import * as actions from './actionTypes';
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";
import {contactType, spaceType, userDataType} from "./storeTypes";
import {processResult} from "immer/dist/core/finalize";
import {UPDATE_SPACE_MESSAGES} from "./actionTypes";

interface stateType {
    contacts: Array<contactType> | [],
    user: userDataType | {},
    spaces: Array<spaceType> | [],
    unreadMessages: number
}

let defaultState:stateType= {
    contacts: [],
    user: {},
    spaces: [],
    unreadMessages: 0
}

const reducer = (state = defaultState, action: any) : stateType => {
    switch (action.type) {
        case actions.ADD_CONTACT:
            return produce(state, draft => {
                let contacts:WritableDraft<contactType>[] = draft.contacts;
                let contactData:contactType = action.payload.contact
                contacts.push(contactData)
            });
        case actions.CLEAR_CONTACTS:
            return produce(state, draft => {
                draft.contacts = [];

                return draft
            })
        case actions.SET_USER_DATA:
            return produce(state, draft => {
                draft.user = action.payload.data

                return draft
            })
        case actions.CLEAR_SPACES:
            return produce(state, draft => {
                draft.spaces = [];

                return draft
            })
        case actions.CREATE_SPACE:
            return produce(state, draft => {
                draft.spaces = [...draft.spaces, action.payload.space];

                return draft
            })
        case actions.UPDATE_CONTACT:
            return produce(state, draft => {
                draft.contacts[action.payload.id] = action.payload.contact;

                return draft
            })
        case actions.UPDATE_SPACE_MEMBER:
            return produce(state, draft => {
                draft.spaces[action.payload.spaceIndex].members[action.payload.memberIndex] = action.payload.member;

                return draft
            })
        case actions.UPDATE_USER_STATUS:
            return produce(state, draft => {
                draft.user = {...draft.user, active: action.payload.active}
            })
        case actions.ADD_MESSAGE:
            return produce(state, draft => {
                draft.spaces = draft.spaces.map(space => {
                    if (space.id === action.payload.spaceId) {
                        space.messages = [...space.messages, action.payload.message]
                    }
                    return space
                });
            })
        case actions.UPDATE_MESSAGE:
            return produce(state, draft => {
                draft.spaces = draft.spaces.map(space => {
                    if (space.id === action.payload.spaceId) {
                        //space.messages = [...space.messages, action.payload.message]
                        space.messages = space.messages.map(message => {
                            if (message.message_id === action.payload.message.message_id) {
                                message = action.payload.message
                            }

                            return message
                        })
                    }
                    return space
                });
            })
        case actions.MARK_MESSAGES_AS_READ:
            return produce(state, draft => {
                draft.spaces = draft.spaces.map(space => {
                    if (space.id === action.payload.spaceId) {
                        space.messages = space.messages.map(message => {
                            if (message.sender === action.payload.userId) {
                                message.status = {...message.status, ...{
                                        delivered_to_receiver: true,
                                        read_by_receiver: true
                                    }}
                            }
                            return message
                        })
                        space.unread_messages = 0
                    }
                    return space
                })
            })
        case actions.MARK_MESSAGES_AS_DELIVERED:
            return produce(state, draft =>  {
                draft.spaces = draft.spaces.map(space => {
                    if (space.id === action.payload.spaceId) {
                        space.messages = space.messages.map(message => {
                            if (message.sender === action.payload.userId) {
                                if (message.status.sent_to_receiver && !message.status.delivered_to_receiver && !message.status.read_by_receiver) {
                                    message.status = {...message.status, ...{
                                            delivered_to_receiver: true
                                        }}
                                    console.log({ deliveredMessage: message })
                                }

                            }
                            return message
                        })
                    }
                    return space
                })
            })
        case actions.UPDATE_FAVORITE_STATUS:
            return produce(state, draft =>  {
                draft.contacts[action.payload.contactId].in_favorites = action.payload.inFavorites
            })
        case actions.UPDATE_UNREAD_MESSAGES:
            return produce(state, draft => {
                draft.unreadMessages = action.payload.unreadMessages
            })
        case actions.EDIT_SPACE_MESSAGE:
            return produce(state, draft => {
                draft.spaces = draft.spaces.map(space => {
                    if (space.id === action.payload.spaceId) {
                        space.messages = space.messages.map(message => {
                            let messageData = message;

                            if (message.id === action.payload.message.id) {
                                messageData = action.payload.message
                            }

                            return messageData
                        })
                    }
                    return space
                })
            })
        case actions.DELETE_MESSAGE:
            return produce(state, draft => {
                draft.spaces = draft.spaces.map(space => {
                    if (space.id === action.payload.spaceId) {
                        space.messages = space.messages.filter(message => message.id !== action.payload.messageId);
                    }
                    return space
                })
            })
        case UPDATE_SPACE_MESSAGES:
            return produce(state, draft => {
                draft.spaces = draft.spaces.map(space => {
                    if (space.id === action.payload.spaceId) {
                        space.messages = [];
                        space.messages = action.payload.messages
                    }
                    return space
                })
            })
        default:
            return state
    }
}

export default reducer
