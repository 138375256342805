export interface actionData {
    type: string,
    payload: object | Array<object>
}

export interface contactType {
    active: boolean,
    id: number,
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    email_verified_at: string | null,
    in_favorites: boolean,
    profile_picture: string,
    address: string
}

export interface messageDataType {
    id: number,
    space_id: number,
    message: string | File,
    message_type: "text" | "file",
    message_id: string,
    sender: number,
    receiver: number,
    status: messageStatusType,
    created_at: string,
    updated_at: string
    saved: boolean,
    file_name?: string,
    file_extension?: string,
    errorSaving?: boolean
}

export interface messageStatusType {
    sent_to_receiver: boolean,
    delivered_to_receiver: boolean,
    read_by_receiver: boolean
}

export interface messageType {
   data: messageDataType[],
   status: messageStatusType[]
}
export const contactSample:contactType = {
    active: false,
    id: 0,
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    email_verified_at: "",
    in_favorites: false,
    profile_picture: "",
    address: ""
};

export interface authFieldType {
    name: string,
    type: string,
    label?: string,
    value: string,
    placeholder: string,
    hasError: boolean,
    errorText?: string,
    icon?: JSX.Element,
    halfWidth?: boolean,
    success?: boolean
}

export interface registrationRequestType {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    phone: string,
    address: string,
    password_confirmation: string
}

export const registerRequestObject:registrationRequestType = {
   firstname: '',
    lastname: '',
    email: '',
    password: '',
    phone: '',
    address: '',
    password_confirmation: ''
}

export interface registrationDataType {
    verification: {
        code: string,
        issuedAt: string
    }
}

export const registrationDataSample:registrationDataType = {
    verification: {
        code: '',
        issuedAt: ''
    }
}

export interface userDataType {
    id: number,
    token: string,
    active: boolean,
    profilePictureUrl?: string
}

export interface spaceType {
    id: number,
    contacts_id: number,
    member: number,
    unread_messages: number,
    created_at: string,
    space: string,
    members: Array<contactType>,
    messages: Array<messageDataType>
}


export interface pusherConfigInterface {
    cluster: string,
    forceTLS?: boolean
}

export type mediaType = "audio" | "picture" | "file" | "video" | "voice-note";

export interface propData {
    darkMode: boolean,
    switchMode:(mode: boolean) => void
}

export interface feedbackType {
    type: 'error' | 'success',
    text: string,
    open: boolean
}

