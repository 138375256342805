import React, {useState} from "react";
import "../css/components/picture-element.scss"
import {HiOutlineDownload} from "react-icons/hi";
import {GrClose} from "react-icons/gr";
import {downloadFile} from "../helpers/functions";
interface propTypes {
    src: string
}
const PictureElement:React.FC<propTypes> = ({ src }) => {
    const [showPictureModal, updateShowPictureModal] = useState<boolean>(false)
    return <>
        <div className={'picture-element'}
             onClick={() => updateShowPictureModal(true)}

        >
            <img src={src} alt={'Chat space item'}/>
        </div>

        <PictureModal showModal={showPictureModal} src={src} onClose={() => updateShowPictureModal(false)}/>
    </>
}

interface modalType extends propTypes {
    showModal: boolean,
    onClose: () => void
}
const PictureModal:React.FC<modalType> = ({ showModal, src, onClose }) => {


    return <div className={`picture-modal ${showModal ? 'view' : 'hide'}`}>
        <div className={'modal-header page-width centered'}>
            <div className={'header-item download'} onClick={() => downloadFile(src)}>
                <HiOutlineDownload/>
            </div>

            <div className={'header-item close'} onClick={() => onClose()}>
                <GrClose/>
            </div>
        </div>

        <div className={'media-element page-width centered'}>
            <div className={'media-item'}>
                <img src={src} alt={'Space sent item'}/>
            </div>
        </div>
    </div>
}

export default PictureElement
