import * as actions from './actionTypes';
import {actionData, userDataType, contactType, spaceType, messageDataType} from "./storeTypes";

export const addContact = (contact: contactType) : actionData => {
    return {
        type: actions.ADD_CONTACT,
        payload: {
            contact
        }
    }
}

export const clearContacts = () : actionData => {
    return {
        type: actions.CLEAR_CONTACTS,
        payload: {}
    }
}

export const setUserData = (data: userDataType | {}) :actionData => {
    return {
        type: actions.SET_USER_DATA,
        payload: {
            data
        }
    }
}

export const createStoreSpace = (space: spaceType) : actionData => {
    return {
        type: actions.CREATE_SPACE,
        payload: {
            space
        }
    }
}

export const clearSpaces = () :actionData => {
    return {
        type: actions.CLEAR_SPACES,
        payload: {}
    }
}

export const updateContact = (id: number, contact: contactType) :actionData => {
    return {
        type: actions.UPDATE_CONTACT,
        payload: {
            id,
            contact
        }
    }
}

export const updateSpaceMember = (spaceIndex: number, memberIndex: number, member: contactType) :actionData => {
    return {
        type: actions.UPDATE_SPACE_MEMBER,
        payload: {
            spaceIndex,
            memberIndex,
            member
        }
    }
}

export const updateUserStatus = (active: boolean):actionData => {
    return {
        type: actions.UPDATE_USER_STATUS,
        payload: {
            active
        }
    }
}


export const addMessage = (spaceId: number, message: messageDataType) : actionData => {
    return {
        type: actions.ADD_MESSAGE,
        payload: {
            spaceId,
            message
        }
    }
}


export const updateStateMessage = (spaceId: number, message: messageDataType) : actionData => {
    return {
        type: actions.UPDATE_MESSAGE,
        payload: {
            spaceId,
            message
        }
    }
}

export const MarkMessagesRead = (spaceId: number, userId: number) : actionData => {
    return {
        type: actions.MARK_MESSAGES_AS_READ,
        payload: {
            spaceId,
            userId
        }
    }
}

export const MarkMessagesDelivered = (spaceId: number, userId: number) : actionData => {
    return {
        type: actions.MARK_MESSAGES_AS_DELIVERED,
        payload: {
            spaceId,
            userId
        }
    }
}

export const updateFavoriteStatus = (contactId: number, inFavorites: boolean) : actionData => {
    return {
        type: actions.UPDATE_FAVORITE_STATUS,
        payload: {
            contactId,
            inFavorites
        }
    }
}


export const updateUnreadMessages = (unreadMessages: number) : actionData => {
    return {
        type: actions.UPDATE_UNREAD_MESSAGES,
        payload: {
            unreadMessages
        }
    }
}

export const editSpaceMessageData = (spaceId: number, message: messageDataType) : actionData => {
    return {
        type: actions.EDIT_SPACE_MESSAGE,
        payload: {
            spaceId,
            message
        }

    }
}

export const deleteSpaceMessage = (spaceId: number, messageId: number) : actionData => {
    return {
        type: actions.DELETE_MESSAGE,
        payload: {
            spaceId,
            messageId
        }

    }
}

export const updateSpaceMessages = (spaceId: number, messages: messageDataType[]) => {
    return {
        type: actions.UPDATE_SPACE_MESSAGES,
        payload: {
            spaceId,
            messages
        }
    }
}


