import React from "react";
import "../css/components/media-view.scss";
import MediaElement from "../components/MediaElement";

export const MediaView = () => {
    return <div className={'media-view'}>
        <MediaElement
            mediaType={'picture'}
            loaded={true}
            src={require('../files/images/bird.jpg')}
        />
    </div>
}
