import React, {useEffect, useRef, useState} from "react";
import "../css/components/audio-player.scss"
import {BsMusicNoteBeamed} from "react-icons/bs";
import RangeSlider from "./RangeSlider";
import {splitTime} from "../helpers/mediaFunctions";
import {FaPlay, FaPause} from "react-icons/fa";
import {HiOutlineDownload} from "react-icons/hi";
import {is} from "immer/dist/utils/common";
import {debounce} from "lodash";
import {downloadFile} from "../helpers/functions";
interface propTypes {
    src: string,
    layout: "regular" | "flex"
}
const AudioPlayer:React.FC<propTypes> = ({ layout, src}) => {
    let audioElement = useRef<HTMLAudioElement | null>(null);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const [audioInterval] = useState(1000);

    let playTimeout = useRef<number | null>(null);

    useEffect(() => {

        let element = audioElement.current as HTMLAudioElement;

       if (currentTime > 0) {
           playTimeout.current = window.setTimeout(() => {
               let audioTime = Math.round(element.currentTime);
              // console.log({ audioTime })
               setCurrentTime(audioTime + 1)
           }, audioInterval)

       }
    }, [currentTime, audioElement])

   function setAudioDuration() {
        if (audioElement.current != null) {
            let element = audioElement.current as HTMLAudioElement;

            let duration = Math.round(element.duration);

           // console.log({ element: element.duration })
            setDuration(Math.round(duration))
        }
    }

    const togglePlay = () => {
       let audio = audioElement.current as HTMLAudioElement;
       if (audio.paused) {
           playAudio()
       } else { pauseAudio() }

    }

    const playAudio = () => {
        let audio = audioElement.current as HTMLAudioElement;
        audio.play()
        setIsPlaying(true)
        //console.log({ audioTime: audio.currentTime })

        const setAudioTime = debounce(() => {
            let audioTime = Math.round(audio.currentTime);
            setCurrentTime( audioTime + 1)
        }, 500)

        setAudioTime()
    }

    const pauseAudio = () => {
        let audio = audioElement.current as HTMLAudioElement;
        audio.pause()
        if (playTimeout.current !== null) {
            window.clearTimeout(playTimeout.current)
        }
        setIsPlaying(false)
    }


    function adjustTime(value: number) {
        let audio = audioElement.current as HTMLAudioElement;

        setCurrentTime(value)
        audio.currentTime = value;
        //console.log({ value, currentTime: audio.currentTime })
        audio.pause();


        setIsPlaying(false)


    }

    const resumeAudio = debounce(() => {
        let audio = audioElement.current as HTMLAudioElement;
      //  console.log(currentTime, audio.currentTime)
        audio.play();

        setIsPlaying(true)
    }, 100)

    function getNewDuration() {
        let audio = audioElement.current as HTMLAudioElement;


        if (duration === Infinity) {
            let audioDuration = Math.round(audio.duration)
            setDuration(audioDuration)

           // console.log({ current: audio.currentTime })
        }

    }

/*
    useEffect(() => {
        document.addEventListener("keypress", function (e) {
            if (e.code.toLowerCase() === 'space') {
                togglePlay();
            }
        })
    }, [])*/
    return <div className={'audio-element'}>
        <audio ref={element => audioElement.current = element}
               onLoadedData={() => setAudioDuration()}
               onPlay={() => playAudio()}
               onPause={() => pauseAudio()}
               onDurationChange={() => getNewDuration()}
               src={src}
        >
            <source src={src} type={'audio/mpeg'}/>
            <source src={src} type={'audio/ogg'}/>

            <>Your browser does not support audio.</>
        </audio>
        {
            layout === 'regular' ?  <div className={'media-placeholder audio-container'}>
                <BsMusicNoteBeamed/>

                {
                    duration > 0 ?  <div className={'audio-controls'}>
                        <RangeSlider min={0}
                                     max={duration}
                                     value={currentTime}
                                     onChange={value => adjustTime(value)}
                                     onSelect={() => resumeAudio()}
                        />
                        <div className={'audio-times flexbox space-between'}>
                            <span>{ currentTime >= 0 ? splitTime( currentTime ) : null}</span>
                            <span>{ currentTime > 0 ? "-" : "" }{ splitTime( duration - currentTime )}</span>
                        </div>

                        <div className={'control-row'}>
                            <div className={'control-items flexbox align-center'}>
                                <div className={'control-button'} onClick={() => togglePlay()}>
                                    { !isPlaying ? <FaPlay/> : <FaPause/>}
                                </div>
                            </div>

                            <div className={'download-button'} onClick={() => downloadFile(src)}>
                                <HiOutlineDownload/>
                            </div>
                        </div>
                    </div> : null
                }
            </div> : <div className={'media-placeholder voice-note audio-container'}>
                <div className={'flexbox voice-player flexbox align-center'}>

                    <div className={'control-button'} onClick={() => togglePlay()}>
                        { !isPlaying ? <FaPlay/> : <FaPause/>}
                    </div>


                   <div className={'slider-section'}>
                       <RangeSlider min={0}
                                    max={duration === Infinity ? 0 : duration}
                                    value={currentTime}
                                    onChange={value => adjustTime(value)}
                                    onSelect={() => resumeAudio()}
                       />
                   </div>
                </div>

                <div className={'audio-duration'}>
                    { splitTime( duration !== Infinity ? duration : 0 ) }
                </div>
            </div>
        }
    </div>
}

export default AudioPlayer
