import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import ModeSwitch from "./ModeSwitch";
import { RiWechatLine } from "react-icons/ri";
import {RiUserLine} from "react-icons/ri";
import { MdOutlineLogin } from "react-icons/md";
import ImagePlaceholder from "./ImagePlaceholder";
import { IoIosArrowDown } from "react-icons/io";
import { CgLogOut } from "react-icons/cg";
import appStore from "../app/redux/store";
import {userDataType} from "../app/redux/storeTypes";
import {setUserData} from "../app/redux/actions";
import {baseApi, baseURL} from "../api/base";
import {watchUserStatus} from "./chats/functions/ChatFunctions";
import {
    dataInterface,
    eventInterface,
    getUnreadMessages, listenForNavNotification,
    listenForNotifications,
    listenForStatusChange,
    messageDataInterface, updateMessageCount
} from "../api/pusherFunctions";


interface propData {
    darkMode: boolean,
    switchMode:(mode: boolean) => void
}
interface navItemTypes {
    linkText: string,
    linkTo: string,
    icon: JSX.Element
}
const Navigation:React.FC<propData> = ({ darkMode, switchMode }) => {


   /* useEffect(() => {
        console.log('Dark mode:', darkMode)
    }, [darkMode])*/


    let navObject = [
        {
            linkText: 'Chats',
            linkTo: '/spaces',
            icon: <RiWechatLine className={'nav-icon'}/>
        },

        {
            linkText: 'Contacts',
            linkTo: '/',
            icon: <RiUserLine className={'nav-icon'}/>
        }
    ]

    let userDropdown:navItemTypes[] = [
        {
            linkText: 'Your profile',
            linkTo: '/profile',
            icon: <RiUserLine className={'dropdown-icon'} />
        },
        {
            linkText: 'Log out',
            linkTo: '/logout',
            icon: <CgLogOut className={'dropdown-icon'} />
        }
    ]

    let authObject:navItemTypes =  {
        linkText: 'Sign in',
        linkTo: '/login',
        icon: <MdOutlineLogin className={'nav-icon'} />
    }

    const [navLinks, updateNavLinks] = useState<navItemTypes[]>(navObject);
    const [authenticatedUser, setAuthenticatedUser] = useState<userDataType | {}>({});
    const [navDropdown] = useState<navItemTypes[]>(userDropdown);
    const [unreadMessages, updateUnreadMessages] = useState<number>(0);





  /*  if ("id" in user && user) {

        let statusChannel = listenForStatusChange();
        let messageChannel = listenForNotifications();

        let statusEvent:string = "user.status";
        let newMessageEvent:string = "new.message";
        let readMessageEvent:string = "messages.read";

        /!*const userStatusCallback = (eventData: eventInterface) : void => {
            console.log('Nav callback:', eventData)
            if ((eventData.user === user.id) && eventData.active) {
                updateMessageCount()
            }
        }*!/

        const messageCallback = (eventData: messageDataInterface) : void => {
            let existingSpaces = appStore.getState().spaces.filter(space => space.space === eventData.space_id);

            if (existingSpaces.length > 0) {
                updateMessageCount()
            }
        }

        const readMessageCallback = (eventData: dataInterface) : void => {
            let existingSpaces = appStore.getState().spaces.filter(space => space.member === eventData.user || space.contacts_id === eventData.user);

            if (existingSpaces.length > 0) {
                updateMessageCount()
            }
        }

        messageChannel.bind(readMessageEvent, readMessageCallback);
        messageChannel.bind(newMessageEvent, messageCallback)
        statusChannel.bind(statusEvent, userStatusCallback)
    }*/


    useEffect(() => {
        //localStorage.removeItem('userData')
        const userData = localStorage.getItem('userData');

        if (userData == null) {
            appStore.dispatch(setUserData({}))

        } else {
            let savedUser = JSON.parse(userData);

            setAuthenticatedUser({...savedUser})
            appStore.dispatch(setUserData(savedUser));
        }
        appStore.subscribe(() => {
            let currentState = appStore.getState();
            setAuthenticatedUser({...currentState.user})

            let storeUnreadMessages = appStore.getState().unreadMessages;

            updateUnreadMessages(storeUnreadMessages)

        });


        let navigationChannel = listenForNavNotification();

        let notifEvent:string = "nav.message";


        interface callbackIype {
            space: string
        }
        const eventCallback = (eventSpace: callbackIype) => {
        //  console.log({ eventSpace, spaces: appStore.getState().spaces })

            let existingSpaces = appStore.getState().spaces.filter(space => space.space === eventSpace.space);
           // console.log({ existingSpaces })
            if (existingSpaces.length > 0) {
              //  console.log('Found existing space')
                updateMessageCount();
            }

        }

        navigationChannel.bind(notifEvent, eventCallback);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let dataLength:number = Object.values(authenticatedUser).length;
        if (dataLength === 0) {
             let existingItem = navLinks.filter(link => link.linkText === 'Sign in');
            if (existingItem.length === 0) {
                updateNavLinks([authObject])
            }
        } else {
            updateNavLinks([...navObject])
        }

        // eslint-disable-next-line
    }, [authenticatedUser])

    return <nav className={'navigation'}>
            <div className={'navigation-main page-width flexbox align-center space-between centered'}>
                <header className={'app-name'}><Link to={'/'}>Starling</Link></header>

               <div className={'nav-items flexbox align-center'}>
                   <ul className={'nav-list'}>
                       <NavLinks navLinks={navLinks} unreadMessages={unreadMessages}/>

                       {
                           Object.values(authenticatedUser).length > 0 ? <li>
                               <UserDropdown dropdown={navDropdown} user={authenticatedUser}/>
                           </li> : null
                       }


                   </ul>
                   <div className={'toggle-section'}>
                       <ModeSwitch checked={darkMode} stateChange={state => {
                         //  console.log('State data:', state)
                           switchMode(state)
                       }
                       }/>
                   </div>
               </div>
            </div>

        <div className={'mobile-nav flexbox align-center justify-center'}>
            <ul className={'nav-list'}>
                <NavLinks navLinks={navLinks} unreadMessages={unreadMessages}/>

                {
                    Object.values(authenticatedUser).length > 0 ?  <li>
                        <UserDropdown dropdown={navDropdown} user={authenticatedUser}/>
                    </li> : null
                }
            </ul>
        </div>
    </nav>
}

interface propTypes {
    dropdown: navItemTypes[],
    user: userDataType | {}
}

const UserDropdown:React.FC<propTypes> = ({ dropdown, user  }) => {
    interface userType {
        name: string,
        initials: string,
        profilePicture: string
    }
    let userSample = {
         name: '',
         initials: '',
         profilePicture: ''
    }
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [userName, updateUserName] = useState<userType>(userSample)

    useEffect(() => {
        document.addEventListener('click', function (event:MouseEvent) {
            let target = event.target as HTMLElement

            if (target.closest(".user-section") == null) {
                    setDropdownOpen(false)
            }
        })

        if ("id" in user && user.id) {
            baseApi.get(`get/${user.id}`)
                .then(response => {
              //      console.log({ response })
                    let sample = {
                        name: response.data.firstname,
                        initials: `${response.data.firstname[0].toUpperCase()}.${response.data.lastname[0].toUpperCase()}`,
                        profilePicture: `${baseURL}${response.data.profile_picture}`
                    }
                    updateUserName({...sample})
                })
                .catch(error => {
                    console.log({ error })
                })
        }

        //eslint-disable-next-line
    }, [])

    return  <div className={'user-section-data'}>
        <div onClick={() => setDropdownOpen(!dropdownOpen)} className={`user-section ${dropdownOpen ? 'open': ''}`}>
            <div className={'relative-item user-data-item'}>
                <>{ userName.profilePicture === '' || userName.profilePicture === baseURL ? <ImagePlaceholder/> : <img src={userName.profilePicture}/>}</>
                <div className={'user-dropdown'}>
                    <ul className={'user-dropdown-content'}>
                        {
                            dropdown.map((link, linkIndex) => {
                                return <li key={linkIndex}>
                                    <Link to={link.linkTo}>
                                        { link.icon }
                                        <span>{ link.linkText }</span>
                                    </Link>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </div>
            {
                Object.values(userName).length > 0 ? <div className={'username-items'}>
                    <span className={'username'}>{ userName.name }</span>
                    <span className={'initials'}>{ userName.initials }</span>
                </div> : null
            }
            <IoIosArrowDown className={'toggle-icon'}/>
        </div>

    </div>
}

interface linkProps {
    navLinks: navItemTypes[],
    unreadMessages: number
}
const NavLinks:React.FC<linkProps> = ({ navLinks, unreadMessages }) => {
    return <>
        {
            navLinks.map((link, index: number) => {
                let { linkText, linkTo, icon }:navItemTypes = link
                return (
                    <li className={'main-nav'} key={index}>
                        <Link to={linkTo} className={'flexbox align-center'}>
                            {icon}
                            <span>{linkText}</span>
                            {
                                linkText.toLowerCase() === 'chats' && unreadMessages > 0
                                    ? <span className={'message-count'}>
                                                       <span>{ unreadMessages }</span>
                                               </span> : null
                            }
                        </Link>
                    </li>
                )
            })
        }
    </>
}
export default Navigation
