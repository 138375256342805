import React, {useEffect, useState} from "react";
import {contactType, contactSample} from "../app/redux/storeTypes";
import ContactDetails from "../components/contacts/ContactDetails";
import appStore from "../app/redux/store";
import { useParams } from "react-router-dom";
import ContactPlaceholder from "../components/contacts/ContactPlaceholder";
import "../css/components/contacts/contact-details.scss";
import {baseApi} from "../api/base";
import ErrorHandler from "../components/ErrorHandler";
import {debounce, parseInt} from "lodash";
import {updateContact, updateFavoriteStatus} from "../app/redux/actions";
import {manageFavorites} from "../helpers/functions";
import {eventInterface, getContactIndex, listenForStatusChange} from "../api/pusherFunctions";
import app from "../App";
import { useNavigate } from "react-router-dom";


const ContactView = () => {
        let parameters = useParams();
        let navigate = useNavigate();

        const [loadingContact, setLoadingContact] = useState(true);
        const [activeContact, setActiveContact] = useState<contactType | {}>();
        const [errorText, setErrorText] = useState<string>('');



   const setFavoriteStatus = debounce((contactId: number) => {
        let user = appStore.getState().user;
        if ("id" in user && user) {
            baseApi.get(`favorites/${user.id}`, {
                headers: {
                    Accepts: 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            }).then(responseItems => {
                interface responseType {
                    id: number,
                    contacts_id: number,
                    favorite_id: number
                }

                let responseData:responseType[] = responseItems.data

                let favoriteContact = responseData.find(favorite => favorite.favorite_id === contactId);

                if (favoriteContact) {
                    setActiveContact({...activeContact, in_favorites: true})
                }
            })
        }
    }, 500)


        useEffect(() => {
            if ("user" in appStore.getState()) {
                let user = appStore.getState().user
                if ("id" in user && user) {
                    if (parameters.id) {
                        let storeState = appStore.getState();
                        let contactId = parseInt(parameters.id);
                        let matchingContact = storeState.contacts.find(contact => contact.id === contactId);
                        if (matchingContact) {
                            setLoadingContact(false)
                            setActiveContact(matchingContact)
                        } else {
                            baseApi(`get/${parameters.id}`).then(response => {
                                //  console.log('Found contact:', response)
                                setLoadingContact(false)
                                setActiveContact({...response.data})
                            }).catch(error => {
                                setLoadingContact(false)
                               // console.log('Error:', error)

                                if (error.response) {
                                    setErrorText('An unexpected error occurred')
                                    if (error.response.data) {
                                        if (error.response.data.error) {
                                            setErrorText(error.response.data.error)
                                        } else {
                                            setErrorText('An unexpected error occurred')
                                        }
                                    }
                                }
                            })
                        }

                    }

                    let channel = listenForStatusChange();
                    let userEvent = "user.status";

                    const eventCallback = (eventData: eventInterface) : void => {

                        let storeContacts:contactType[] = appStore.getState().contacts;
                        let contact = activeContact as contactType;
                        if (contact) {
                            if ("id" in contact && contact) {
                              //  console.log(eventData)
                                setActiveContact({...contact, active: eventData.active})
                            }
                        }


                        if (appStore.getState().contacts.length > 0) {
                            let contactIndex:number = getContactIndex(eventData.user);

                            if (contactIndex !== -1) {
                                let updatedContact =  {...storeContacts[contactIndex], active: eventData.active};
                                appStore.dispatch(updateContact(contactIndex, updatedContact));
                            }

                        }
                    }

                    channel.bind(userEvent, eventCallback)
                } else {
                    navigate("/login")
                }
            } else {
                navigate("/login")
            }


        }, [])

    useEffect(() => {
        if (!loadingContact) {
            let contact = activeContact as contactType;
            if ("id" in contact && contact) {
                setFavoriteStatus(contact.id)
            }
        }
    }, [loadingContact])



    function handleChange(inFavorites: boolean) {
            if (parameters.id) {
                let contactId = parseInt(parameters.id as string);

               // console.log({ contactId, contacts: appStore.getState().contacts });

                let existingContact = appStore.getState().contacts.find(contact => contact.id === contactId)

                if (existingContact) {
                    appStore.dispatch(updateFavoriteStatus(contactId, inFavorites));
                }


               // appStore.dispatch(updateFavoriteStatus(contactId, inFavorites));

                setActiveContact({...activeContact, in_favorites: inFavorites})

                let store = appStore.getState();

                if ("user" in store && store) {
                    if ("id" in store.user && store.user) {
                        let contact = activeContact as contactType;

                        manageFavorites(store.user.id,contact.id, store.user.token)/*.then(response => {
                            console.log(response)
                        })*/
                    }

                }


            }

    }

    return <>
        {
            loadingContact ?  <ContactPlaceholder/> : errorText !== '' ?
                <ErrorHandler text={errorText}/> : <ContactDetails
                    closable={false}
                    contact={activeContact as contactType}
                    onFavoriteChange={inFavorites => handleChange(inFavorites)}
                />
        }
    </>

}

export default ContactView
