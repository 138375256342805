export const ADD_CONTACT:string = "addContact";
export const CLEAR_CONTACTS:string = "clearContacts";
export const SET_USER_DATA:string = "setUserData";
export const CREATE_SPACE:string = "createSpace";
export const CLEAR_SPACES:string = "clearSpaces";
export const UPDATE_CONTACT:string = "updateContact";
export const UPDATE_SPACE_MEMBER:string = "updateSpaceMember";
export const UPDATE_USER_STATUS:string = "updateUserStatus";
export const ADD_MESSAGE:string = "addMessage";
export const UPDATE_MESSAGE:string = "updateMessage";
export const MARK_MESSAGES_AS_READ:string = "markMessagesAsRead";
export const MARK_MESSAGES_AS_DELIVERED:string = "markMessagesAsDelivered";
export const UPDATE_FAVORITE_STATUS:string = "updateFavoriteStatus";
export const UPDATE_UNREAD_MESSAGES:string = "updateUnreadMessages";
export const EDIT_SPACE_MESSAGE:string = "editSpaceMessage";
export const DELETE_MESSAGE:string = "deleteMessage";
export const UPDATE_SPACE_MESSAGES:string = "updateSpaceMessages";
