import {parseInt} from "lodash";
import {mediaType} from "../app/redux/storeTypes";

export function splitTime(seconds : number) {
    let breakdown:Array<string | number> = [];

    const breakTime = (seconds: number) => {
        let timeDivision =  Math.floor(seconds / 60);
        if (timeDivision >= 60) {
            let hourDivision = Math.floor(timeDivision / 60);
            breakdown.push(hourDivision);

            let newSeconds = seconds  % (60 * 60);

            breakTime(newSeconds)
        } else {
            breakdown.push(timeDivision);
            breakdown.push(seconds % 60)
        }
    }


    breakTime(seconds)

    breakdown = breakdown.map(item => {
        const singleItemString = item.toString().length === 1
        return singleItemString ? `0${item}` : parseInt(item as string)
    })

    return breakdown.toString().replace(/,/g, ":");
}
interface fileType{
    file_name: string,
    file_extension: string
}
export function getFileNameAndExtension(src: string) : fileType {
    let extensionIndex = src.lastIndexOf("."), slashIndex = src.lastIndexOf("/");

    return { file_name: src.slice(slashIndex+1, src.length), file_extension: src.slice(extensionIndex+1, src.length)}
}

export const pictureTypes:Array<string> = ['jpg', 'jpeg', 'png', 'webp', 'gif'];
export const audioTypes:Array<string> = ['mp3', 'mpeg', 'wav', 'webm'];
export const videoTypes:Array<string> = ['mp4', 'ogg', 'mkv', 'mov', 'avi'];
export const fileTypes:Array<string> = ['pdf', 'ppx', 'doc', 'docx', 'exe'];

export function getMediaType(extension: string) : mediaType {



  if (pictureTypes.indexOf(extension) !== -1) {
      return "picture"
  } else if (audioTypes.indexOf(extension) !== -1) {
      return "audio"
  } else if (videoTypes.indexOf(extension) !== -1) {
      return "video"
  } else if (fileTypes.indexOf(extension) !== -1) {
      return "file"
  } else {
      return "file"
  }
}


