import Pusher from "pusher-js";
import {pusherConfig, pusherKey} from "./pusherConfig";
import {debounce} from "lodash";
import appStore from "../app/redux/store";
import { AxiosRequestHeaders } from "axios";
import {baseApi} from "./base";
import {contactSample, contactType, messageDataType, spaceType, userDataType} from "../app/redux/storeTypes";
import {clearSpaces, createStoreSpace, updateUnreadMessages} from "../app/redux/actions";
import {getFileNameAndExtension} from "../helpers/mediaFunctions";

export const pusher = new Pusher(pusherKey, pusherConfig);


export interface eventInterface{
    user: number,
    active: boolean
}

export interface dataInterface {
    user: number,
    messages: messageDataType[]
}

export interface messageDataInterface {
    space_id: string,
    message: messageDataType
}

export const listenForStatusChange = () => {
   // pusher.unsubscribe('status.change');
    return pusher.subscribe('status.change')
}

export const listenForNavNotification = () => {
   // pusher.unsubscribe('nav.notification');
    return pusher.subscribe('nav.notification');
}

export const listenForSpaceNotification = () => {
   // pusher.unsubscribe('space.notification');

    return pusher.subscribe('space.notification');
}

interface activityInterface {
    id: number,
    active: boolean
}


export const setUserStatus = debounce(() => {
    let appUser = appStore.getState().user;
    if ("id" in appUser && appUser) {

        sendUserStatusRequest(appUser)
    }
}, 2000);

export const sendUserStatusRequest = async (appUser: userDataType) => {
    let requestObject:activityInterface = {
        id: appUser.id,
        active: appUser.active
    }

    let requestHeaders:AxiosRequestHeaders = {
        Accepts: 'application/json',
        Authorization: `Bearer ${appUser.token}`
    }
   await baseApi.post('set/status', requestObject, {
        headers: requestHeaders
    })
}

export const createSpace = async (memberId: number) => {
    let savedUser = appStore.getState().user;


    if ("id" in savedUser && savedUser) {
        let requestObject = {
            creator: savedUser.id,
            member: memberId
        }

        let headers:AxiosRequestHeaders = {
            Accepts: 'application/json',
            Authorization: `Bearer ${savedUser.token}`
        }

      return await baseApi.post('space/create', requestObject, {
            headers
        })
    }
}

export const getSpacePosition = (eventData: eventInterface) => {
    interface spaceDataInterface {
        spaceIndex: number,
        memberIndex: number,
        member: contactType
    }

    let spaceData:spaceDataInterface = {
        spaceIndex: -1,
        memberIndex: -1,
        member: contactSample
    }

    appStore.getState().spaces.forEach((space, index) => {
        space.members.forEach((member, userIndex) => {
            if (member.id === eventData.user) {
                spaceData['spaceIndex'] = index;
                spaceData['memberIndex'] = userIndex
                spaceData['member'] = {...member, active: eventData.active}
            }
        })
    })

    return spaceData
}

export const listenForNotifications = () => {
   // pusher.unsubscribe('notification');
   return pusher.subscribe('notification')
}

export const listenForTyping = () => {
    //pusher.unsubscribe('typing.notification');
    return pusher.subscribe('typing.notification');
}

export const SetMessageStatus = async (user: number, space: string, token: string, status: 'delivered' | 'read') => {
    let requestObject = {
        id: user,
        space
    }

    let requestHeaders = {
        Accepts: 'application/json',
        Authorization: `Bearer ${token}`
    }

    return await baseApi.post(`/mark/${status}`, requestObject, {
        headers: requestHeaders,
        timeout: 30000
    })
}

export const sendTypingNotification = async (spaceId: number, userId: number, token: string, type: 'broadcast' | 'clear', signal: AbortSignal) => {
    let requestObject =  {
        user: userId,
        space: spaceId
    }

    let headers = {
        Accepts: 'application/json',
        Authorization: `Bearer ${token}`
    }

    let url = type === 'broadcast' ? '/user/typing' : '/user/typing/clear'

    return await baseApi.post(url, requestObject, {
        headers,
        signal
    })

}

export const getContactIndex = (user: number) : number => {
    let storeContacts = appStore.getState().contacts as contactType[];

    let contactIndex:number = -1;
    storeContacts.forEach((contact , index)=> {
        if (contact.id === user) {
            contactIndex = index
        }
    });

    return contactIndex

}

export const getUnreadMessages = async (id: number, token: string) => {

    let requestHeaders:AxiosRequestHeaders = {
        Accepts: `application/json`,
        Authorization: `Bearer ${token}`
    }

    return await baseApi.get(`get/unread-messages/${id}`, {
        headers: requestHeaders
    })
}

export function updateMessageCount() : void {
   // console.log('About to call API')
    let user = appStore.getState().user as userDataType;
    getUnreadMessages(user.id, user.token).then(response => {
        let unread:number = response.data.unread_messages

         appStore.dispatch(updateUnreadMessages(unread))
    }).catch(error => {
        console.log({ error })
    })
}


export const getUserSpaces = async (id: number, token: string) => {
    return await baseApi.get(`/spaces/${id}`, {
        headers: {
            'Accepts': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

export const addSpacesToStore = (spaceData: spaceType[]) => {
    appStore.dispatch(clearSpaces());

    spaceData.forEach((data: spaceType)=> {
        // console.log({ data })
        data.messages = data.messages.map(message => {
            message.saved = true

            if (message.message_type === 'file') {
                let fileData = getFileNameAndExtension(message.message as string);

                message.file_extension = fileData.file_extension;
                message.file_name = fileData.file_name;
            }
            return message
        })

        appStore.dispatch(createStoreSpace(data))
    })
}

export const editSpaceMessage = async (spaceId: number, messageId: number, message: string, token: string) => {
    let requestObject = {
        space_id: spaceId,
        message_id: messageId,
        message
    }

    let headers:AxiosRequestHeaders = {
        Accepts: 'application/json',
        Authorization: `Bearer ${token}`
    }

   return await baseApi.patch('message/edit', requestObject, {
        headers
    })
}

export const sendDeleteRequest = async (spaceId: number, messageId: number, token: string) => {

    let requestObject = {
        space_id: spaceId,
        message_id: messageId
    }

    let requestHeaders:AxiosRequestHeaders = {
        Accepts: 'application/json',
        Authorization: `Bearer ${token}`
    }

    return await baseApi.post('message/delete', requestObject, {
        headers: requestHeaders
    })
}

export const getSpaceData = async (spaceId: string, userId: number, token: string, timeout = 30000) => {
   return await baseApi.get(`space/${spaceId}/${userId}`, {
        headers: {
            Accepts: 'application/json',
            Authorization: `Bearer ${token}`
        },
        timeout
    })
}

