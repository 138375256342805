import React, {FormEvent, useEffect, useRef, useState} from "react";
import '../css/components/auth/auth.scss';
import { authFieldType } from "../app/redux/storeTypes";
import { MdOutlineLogin, MdOutlineEmail } from "react-icons/md";
import { GrKey } from "react-icons/gr";
import {Link} from "react-router-dom";
import {baseApi} from "../api/base";
import ErrorHandler from "../components/ErrorHandler";
import { useNavigate } from "react-router-dom";
import appStore from "../app/redux/store";
import {setUserData} from "../app/redux/actions";
import Spinner from "../components/Spinner";


const LoginView = () => {
    let navigate = useNavigate()
    let formFields:authFieldType[] = [
        {
            name: 'email',
            placeholder: 'Your email address',
            value: '',
            hasError: false,
            errorText: '',
            type: 'text',
            icon: <MdOutlineEmail className={'control-icon'}/>,
            success: false
        },

        {
            name: 'password',
            placeholder: 'Your password',
            value: '',
            hasError: false,
            errorText: '',
            type: 'password',
            icon: <GrKey className={'control-icon'}/>,
            success: false
        }
    ]


    let [fields, updateFields] = useState<authFieldType[]>(formFields);
    let [attemptingLogin, setAttemptingLogin] = useState<boolean>(false);
    let [requestError, setRequestError] = useState<string>('');

    let requestTimeout = useRef<null | number>(null);

    useEffect(() => {
        let savedUser = appStore.getState().user

        if (Object.values(savedUser).length > 0) {
            navigate('/')
        }
    }, [navigate])
    const setFieldValue = (index: number, value: string) : void => {
        let fieldState = [...fields];

        fieldState[index].value = value
        fieldState[index].hasError = value === ''
        if (fieldState[index].success) {
            fieldState[index].success = false
        }

        updateFields(fieldState)
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        interface requestInterface {
            email: string,
            password: string
        }
        let requestObject:requestInterface = {
            email: '', password: ''
        }

        fields.forEach(field => {
            requestObject[field.name as keyof requestInterface] = field.value
        })


        let fieldItems = [...fields].map(field => {
            field.hasError = false;
            field.errorText = '';
            field.success = false

            return field
        })

        updateFields([...fieldItems])

      //  console.log({ requestObject })
        setAttemptingLogin(true)
        baseApi.post('login', requestObject)
            .then(response => {
             //   console.log({ response })

                let userData = {...response.data.user, active: true}
                localStorage.setItem('userData', JSON.stringify(userData))

                appStore.dispatch(setUserData(userData))

                updateFields([...fields].map(field => {
                    field.success = true;
                    return field
                }))

                setAttemptingLogin(false)

                setTimeout(() => {
                    navigate('/')
                }, 1000)
            }).catch(error => {
                //console.log({ error })


                setAttemptingLogin(false)

                if (error.response) {
                    setRequestError(error.response.message);
                    if (error.response.data) {
                        if (error.response.data.errors) {
                            let fieldClone:authFieldType[] = [...fields];

                            let errors = error.response.data.errors, errorKeys = Object.keys(errors);

                            fieldClone = fieldClone.map(field => {
                                if (errorKeys.indexOf(field.name) !== -1) {
                                    field.hasError = true;
                                    field.errorText = errors[field.name].join(",")
                                }

                                return field
                            })

                            updateFields([...fieldClone])

                        } else {
                            setRequestError('An unexpected error occurred');
                        }
                    } else {
                        setRequestError('An unexpected error occurred');
                    }
                } else {
                    setRequestError('An unexpected error occurred');
                }


                requestTimeout.current = window.setTimeout(() => {
                    setRequestError('')
                }, 3000)

        })
    }

    return <div className={'auth-container login-container'}>
        <div className={'detailed-container'}>
            <header className={'auth-header'}>Sign in and connect</header>

            <form method={'post'} action={''} onSubmit={event => handleSubmit(event)} className={'auth-section login-section'}>
                {
                    fields.map((field, index) => {
                        return <div className={'input-control'} key={index}>
                                   <div className={'relative-item'}>
                                       {field.label ? <label>{field.label}</label> : null}
                                       {field.icon ? field.icon : null}
                                       <input
                                           className={`${field.hasError ? 'input-error' : field.success ? 'input-success' : ''}`}
                                           type={field.type}
                                           value={field.value}
                                           placeholder={field.placeholder}
                                           onChange={event => setFieldValue(index, event.target.value)}
                                           name={field.name}
                                       />
                                   </div>
                            { field.errorText ? <div className={'error-text'}>{ field.errorText }</div> : null}
                        </div>
                    })
                }

                {
                    requestError ?  <ErrorHandler text={requestError}/> : null
                }


                <div className={'button-container'}>
                    <button type={'submit'} className={'button button-regular flexbox justify-center'} disabled={fields.filter(field => field.value === '').length > 0}>
                        {
                            attemptingLogin ? <Spinner size={15} color={'#f2f2f2'} thickness={2}/>
                                :  <MdOutlineLogin/>
                        }
                    </button>
                </div>
                <div className={'flexbox justify-center return-link'}><div>Don't have an account?</div><Link to={'/register'}>Sign up</Link></div>
            </form>
        </div>
    </div>
}

export default LoginView;
