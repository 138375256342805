import React, {useEffect, useState, useTransition} from "react";
import ChatList from "../components/chats/ChatList";
import { useNavigate } from "react-router-dom";
import "../css/components/space-view.scss";
import {
    addSpacesToStore,
    eventInterface,
    getSpacePosition, getUserSpaces,
   listenForNotifications, listenForSpaceNotification,
    listenForStatusChange
} from "../api/pusherFunctions";
import appStore from "../app/redux/store";
import {updateSpaceMember} from "../app/redux/actions";
import {spaceType, userDataType} from "../app/redux/storeTypes";

const SpaceView:React.FC = () => {
    let navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        let channel = listenForNotifications();
        channel.unbind("new.message");
    }, [])

    useEffect(() => {

        let userData = localStorage.getItem('userData');

        if (userData == null) {
            navigate('/login')
        }

        let statusChannel = listenForStatusChange();
        let statusEvent:string = "user.status";
        let spaceChannel = listenForSpaceNotification();
        let spaceEvent = "space.message";

        interface dataType {
            space: string
        }
        let eventCallback = (data: dataType) => {
           // console.log({ spaceMessageData: data })
          //  console.log({ data, spaces: appStore.getState().spaces })
            let existingSpaces = appStore.getState().spaces.filter(space => space.space === data.space);

            if (existingSpaces.length > 0) {
               // console.log({ existingSpaces })
               let user = appStore.getState().user as userDataType;

                getUserSpaces(user.id, user.token).then(response => {
                    addSpacesToStore(response.data);

                   setRefresh(true)
                })
            }
        }

        let statusCallback = function (eventData: eventInterface) : void {
            let spaceData = getSpacePosition(eventData);

            if (spaceData) {
                if (spaceData.spaceIndex !== -1 && spaceData.memberIndex !== -1) {
                    appStore.dispatch(updateSpaceMember(spaceData.spaceIndex, spaceData.memberIndex, spaceData.member))

                    setRefresh(true)
                }
            }
        }

        statusChannel.bind(statusEvent, statusCallback);
        spaceChannel.bind(spaceEvent, eventCallback)

    }, [])
    return <>
       <div className={'main-view centered'}>
           <ChatList refresh={refresh}/>
       </div>
    </>
}

export default SpaceView

