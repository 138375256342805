import axios, {AxiosInstance, AxiosRequestHeaders} from "axios";

axios.defaults.withCredentials = true;
export const baseURL:string = "https://api.msstarling.com/"
export const localBaseURL:string = 'http://127.0.0.1:8000/';
export const storageURL:string = `${baseURL}storage/`;

export const baseApi:AxiosInstance = axios.create({
    baseURL: `${baseURL}api/contacts`
})

export const headers:AxiosRequestHeaders = {
    'Accepts': 'application/json'
}
