import React from "react";
import "../css/components/slider-feedback.scss";
import {GrCheckmark} from "react-icons/gr";
import {AiFillWarning} from "react-icons/ai";
import {feedbackType} from "../app/redux/storeTypes";



const SliderFeedback:React.FC<feedbackType> = ({ type, text, open }) => {
    return <div className={`slider-feedback ${open ? 'open' : 'closed'} ${type}`}>
        <div className={'icon-section'}>
            <div className={`icon-holder ${type}`}>
                {
                    type === 'success' ? <GrCheckmark/> : <AiFillWarning/>
                }
            </div>
        </div>

        <div className={'feedback-text'}>{ text }</div>
    </div>
}

export default SliderFeedback
