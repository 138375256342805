import React from "react";
import {BiError} from "react-icons/bi";

interface propTypes {
    text: string
}
const ErrorHandler:React.FC<propTypes> = ({ text }) => {
    return <div className={'error-section'}>
        <BiError className={'error-icon'}/> <div>{ text }</div>
    </div>
}

export default ErrorHandler;
