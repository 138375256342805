import React, {useEffect} from "react";
import appStore from "../app/redux/store";
import {setUserData} from "../app/redux/actions";
import { useNavigate } from "react-router-dom";
import {sendUserStatusRequest} from "../api/pusherFunctions";
import {userDataType} from "../app/redux/storeTypes";
import Spinner from "./Spinner";

const Logout = () => {
    let navigate = useNavigate();
    useEffect(() => {
            let userData:userDataType | {} = appStore.getState().user;

            if ("id" in userData  && userData) {
                let requestUser:userDataType = {...userData, active: false};

                sendUserStatusRequest(requestUser).then(() => {
                    appStore.dispatch(setUserData({}));
                    localStorage.removeItem('userData');
                    navigate('/login')
                })

            }
    },[navigate])

    return <div className={'flexbox justify-center align-center'} style={{ width: '100%', height: 'calc(100vh - 72px)'}}>
        <Spinner size={24} color={'#f5f5f5'} thickness={3}/>
    </div>
}

export default Logout;


