import React, {useEffect, useRef, useState} from "react";
import {FaRegSmile} from "react-icons/fa";
import Picker, {IEmojiData} from "emoji-picker-react";
interface emojiType {
    onEmojiSelect: (emojiObject: IEmojiData) => void
}

const EmojiPicker:React.FC<emojiType> = ({ onEmojiSelect }) => {

    let elementRef = useRef<HTMLElement | null>(null);
    const [showEmojiPicker, updateShowEmojiPicker] = useState<boolean>(false);


    useEffect(() => {
        document.addEventListener("click", function (event) {
            let target = event.target as HTMLElement;
            if (target.closest(".emoji-picker-main") == null) {
                updateShowEmojiPicker(false)
            }
        })
    }, [])

    useEffect(() => {

        let element = elementRef.current as HTMLElement;
        if (!showEmojiPicker) {
            setTimeout(() => {
                element.style.display = "none"
            }, 400)
        } else {
            element.removeAttribute("style")
        }
    }, [showEmojiPicker])

    const handleEmojiClick = (event: any, emojiObject:IEmojiData) => {
       // console.log({ event, emojiObject })
        onEmojiSelect(emojiObject)
    }

    return <div className={'emoji-picker-section'}>
        <div className={'main-picker'}>
            <FaRegSmile className={'smiley-face'} onClick={event =>{
                event.stopPropagation();
                event.preventDefault();
                updateShowEmojiPicker(!showEmojiPicker)
            }
            }/>
         <div className={`emoji-picker-main ${showEmojiPicker ? 'open' : 'closed'}`} ref={element => elementRef.current = element}>
                    <Picker onEmojiClick={handleEmojiClick}/>
         </div>
        </div>
    </div>
}

export default EmojiPicker
