import React from 'react';
/*import logo from './logo.svg';
import { Counter } from './features/counter/Counter';*/
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import ContactList from "./components/contacts/ContactList";
import BaseView from "./views/BaseView";
import ContactView from "./views/ContactView";
import LoginView from "./views/LoginView";
import RegisterView from "./views/RegisterView";
import Logout from "./components/Logout";
import ChatView from "./views/ChatView";
import {MediaView} from "./views/MediaView";
import VoiceRecorder from "./components/VoiceRecorder";
import SpaceView from "./views/SpaceView";
import ChatList from "./components/chats/ChatList";
import ProfileView from "./views/ProfileView";
// import {baseApi} from "./api/base";
/*import Pusher from "pusher-js";
import { pusherKey, pusherCluster} from "./api/pusherConfig";*/

function App() {

 /* useEffect(() => {
    interface pusherConfigInterface {
      cluster: string,
      forceTLS?: boolean
    }

    let pusherConfig:pusherConfigInterface = {
      cluster: pusherCluster,
      forceTLS: true
    }
    let pusher = new Pusher(pusherKey, pusherConfig)
    let notificationChannel = pusher.subscribe('notification');
    let notificationEvent:string = 'new.message';
    let eventCallback = function (data: any) :void {
          console.log('Event data:', data)
    }
    notificationChannel.bind(notificationEvent, eventCallback)
  }, [])*/

  return (
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<BaseView/>}>
              <Route index element={<ContactList/>}/>
              <Route path={'/contacts/:id'} element={<ContactView/>}/>
              <Route path={'login'} element={<LoginView/>}/>
              <Route path={'register'} element={<RegisterView />}/>
              <Route path={'logout'} element={<Logout/>}/>
              <Route path={'/spaces'} element={<SpaceView/>}/>
              <Route path={'/space/:id'} element={<ChatView/>}/>
              <Route path={'/media'} element={<MediaView />}/>
              <Route path={'/voice-record'} element={<VoiceRecorder onRecordEnd={audio => console.log(audio)}/>}/>
              <Route path={'/profile'} element={<ProfileView/>} />
          </Route>
        </Routes>
      </BrowserRouter>
   /* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header>
    </div>*/
  );
}

export default App;
