import React, {useEffect, useState} from "react";
import  '../css/components/switch.scss';
import {v4 as uuidv4} from "uuid";

interface propTypes {
    checked: boolean,
    stateChange: (state : boolean) => void
}
const ModeSwitch:React.FC<propTypes> = (props) => {
    let checked = props.checked
    const [state, updateState] = useState(checked ? checked : false);
    const [switchId] = useState(uuidv4())

    useEffect(() => {
        updateState(checked)
    }, [checked])
    return (
        <>
           <input
               className={'checkbox'}
               type={'checkbox'}
               id={`checkbox-item-${ switchId }`}
               checked={state}
               onChange={event => {
                   updateState(event.target.checked);
                   props.stateChange(event.target.checked)
               }}
           />
            <label className={'slider'} htmlFor={`checkbox-item-${ switchId }`}>
                <span className={'switch mode-switcher'}>
                    {
                      checked ? <img alt={'moon'} src={require('../icons/moon.svg').default} className={'moon'}/> :
                          <img alt={'sun'} src={require('../icons/sun.svg').default} className={'sun'}/>
                    }
                </span>
            </label>
        </>
    )
}

export default ModeSwitch;
