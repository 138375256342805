import React, {useEffect, useState} from "react";
import "../../css/components/chats/list.scss";
import SearchBar from "../SearchBar";
import SpaceImage from "./SpaceImage";
import appStore from "../../app/redux/store";
import Spinner from "../Spinner";
import {MarkMessagesRead, updateSpaceMember} from "../../app/redux/actions";
import {contactSample, contactType, spaceType, userDataType} from "../../app/redux/storeTypes";
import { Link } from "react-router-dom";
import {months, prefixDate} from "../../helpers/dateFunctions";
import {
    addSpacesToStore,
    eventInterface,
    getSpacePosition,
    getUserSpaces,
    listenForStatusChange,
    setUserStatus
} from "../../api/pusherFunctions";
import {getFileNameAndExtension, getMediaType} from "../../helpers/mediaFunctions";
import {debounce} from "lodash";
import {BsFillPlayCircleFill, BsFillPlayFill, BsMusicNoteBeamed} from "react-icons/bs";
import {AiFillFile, AiFillPicture} from "react-icons/ai";
import { mediaType } from "../../app/redux/storeTypes";
import { useNavigate, useParams } from "react-router-dom";
import {baseURL} from "../../api/base";


interface propTypes {
    space: spaceType
}
const SpaceLink:React.FC<propTypes> = ({ space}) => {



    const [member, setMember] = useState<contactType>(contactSample);
    const [dateTime, updateDatetime] = useState('');



    useEffect(() => {
        let savedUser = appStore.getState().user;
        /*let chatMember = space.members.map(member =>{
            if ("id" in savedUser && savedUser.id) {
                if (savedUser.id === member.id) {
                    return -1
                } else {
                    return member
                }
            }
        }).filter(value => value && value !== -1);*/

        if (space.messages.length === 0) {
           let dateItem = returnDateFormat(space.created_at)

            updateDatetime(dateItem)
        } else {
            let lastMesssageDate = space.messages[space.messages.length-1].created_at

            updateDatetime(returnDateFormat(lastMesssageDate))
        }

        let chatMember:contactType = contactSample;

        space.members.forEach(member => {
            if ("id" in savedUser && savedUser) {
                if (member.id !== savedUser.id) {
                    chatMember = member
                }
            }

        })

        if (chatMember.id !== 0) {
            setMember({...chatMember})
        }

        function returnDateFormat(dateString: string) : string {
            let date = new Date(dateString), month = date.getMonth(), day = prefixDate(date.getDate());
            let dayDifference = Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
            let dateItem;
            if (dayDifference > 0) {
                dateItem = `${months[month].slice(0, 3)} ${day}`
            } else {
                dateItem = `${prefixDate(date.getHours())}:${prefixDate(date.getMinutes())}`
            }

            return dateItem
        }
    }, [space])



    function getFileIcon(mediaType : mediaType) :JSX.Element {
        switch (mediaType) {
            case "audio":
                return <BsMusicNoteBeamed/>
            case "file":
                return <AiFillFile/>
            case "video":
                return <BsFillPlayCircleFill/>
            case "voice-note":
                return <BsFillPlayFill/>
            case "picture":
                return <AiFillPicture/>
            default:
                return <AiFillPicture/>
        }
    }



    return <Link to={`/space/${space.space}`} className={'space-item'}>
            <SpaceImage active={member.active} src={`${baseURL}${member.profile_picture}`}/>

            <div className={'space-user'}>
                <div className={'data-row'}>
                    <div className={'username cropped-text'}>{ member.firstname } { member.lastname[0] }.</div>
                    <div className={'timestamp'}>{ dateTime }</div>
                </div>

                <div className={'data-row'}>
                    {
                        space.messages.length > 0 ?
                            <div className={'last-message'}>
                                {
                                    space.messages[space.messages.length-1].message_type === 'file' ?
                                    getFileIcon(  getMediaType( getFileNameAndExtension( space.messages[space.messages.length-1].message as string ).file_extension) )
                                    : null
                                }
                                <div className={'message-text cropped-text'}>
                                    {  space.messages[space.messages.length-1].message_type === 'text' ?
                                        space.messages[space.messages.length-1].message as string :
                                        getMediaType( getFileNameAndExtension( space.messages[space.messages.length-1].message as string ).file_extension)
                                    }
                                </div>
                            </div> : <></>
                    }

                    {
                        space.unread_messages > 0 ? <div className={'unread-notifs'}>
                            { space.unread_messages } unread
                        </div> : <></>
                    }

                </div>
            </div>
        </Link>
}

interface listPropTypes {
    refresh?: boolean,
}
const ChatList:React.FC<listPropTypes> = ({ refresh}) => {
    let navigate = useNavigate();
    let parameters = useParams();

    interface statusInterface {
        text: string,
        error: boolean
    }
    let statusObject = {
        text: 'Fetching spaces',
        error: false
    }
    const [searchValue, updateSearchValue] = useState<string>('');
    const [statusState, updateStatusState] = useState<statusInterface>(statusObject);
    const [fetchingSpaces, updateFetchingSpaces] = useState<boolean>(true);
    const [spaces, updateSpaces] = useState<spaceType[]>([]);

    function showSpaces() {
        let spaces =  appStore.getState().spaces;
        updateSpaces([...spaces]);
    }

   useEffect(() => {
       if (refresh) {
           showSpaces();
       }
   }, [refresh])

    useEffect(() => {
        let store = appStore.getState();

        if ("user" in store && store) {
            let user = store.user ;
            const filterSpaces = debounce((value: string) => {
                  let filteredSpaces:spaceType[] = [];

                  store.spaces.forEach(space => {
                          let member = space.members.find(member => member.id !== (user as userDataType).id);

                          if (member) {
                              let memberName = `${member.firstname} ${member.lastname}`;

                              if (memberName.toLowerCase().includes(value.toLowerCase())) {
                                  filteredSpaces.push(space)
                              }
                          }
                  })

                updateSpaces([...filteredSpaces])
            }, 500)


            filterSpaces(searchValue)
        }
    }, [searchValue])

    useEffect(() => {
        let savedUser = appStore.getState().user;

        updateStatusState({...{
                error: false,
                text: 'Fetching spaces'
            }})

        if ("id" in savedUser && savedUser.id) {
            getUserSpaces(savedUser.id, savedUser.token).then(response => {
              // console.log({ spaceData: response })

                addSpacesToStore(response.data)
               // console.log({'Spaces in redux': appStore.getState().spaces });

                updateFetchingSpaces(false);
                showSpaces();

                setUserStatus();
                if (parameters.id) {
                   // console.log({ params: parameters.id })
                    let space = appStore.getState().spaces.find(space => space.space === parameters.id);

                    if (space) {
                       // console.log({ unread: space.unread_messages })

                        if (space.unread_messages > 0) {
                              let user = appStore.getState().user as userDataType;
                              appStore.dispatch(MarkMessagesRead(space.id, user.id))

                            showSpaces();
                        }

                    }
                }

            }).catch(() => {
              //  console.log({ spaceError: error })

                updateStatusState({...{
                        error: true,
                        text: 'Error fetching spaces'
                    }})
            })
        } else {
            let statusObject:statusInterface = {
                error: true,
                text: 'Unauthenticated access'
            }

            updateStatusState({...statusObject})
            navigate('/login')

        }

        if (refresh === undefined) {

            let channel = listenForStatusChange();

            let activityType:string = "user.status";

            let eventCallback = function (eventData: eventInterface) : void {
              //  console.log({chatListEvent: eventData, spaces: appStore.getState().spaces})


                let spaceData = getSpacePosition(eventData);

                if (spaceData) {
                    if (spaceData.spaceIndex !== -1 && spaceData.memberIndex !== -1) {

                        appStore.dispatch(updateSpaceMember(spaceData.spaceIndex, spaceData.memberIndex, spaceData.member))

                        showSpaces()
                    }
                }
            }


            channel.bind(activityType, eventCallback)

        }
    }, [])
    return <div className={'list-container'}>
        {
            fetchingSpaces ? <div className={'pre-loader'}>
                <div className={`loading-text ${statusObject.error ? 'error': ''}`}>{ statusState.text }</div>

                {
                    !statusState.error ?
                        <div className={'spinner-item'}><Spinner size={16} color={'#f2f2f2'} thickness={2}/></div> : null
                }

            </div> :  <>
                <div>
                    <div className={'list-header'}>
                        <h3 className={'list-heading'}>Spaces</h3>

                        <SearchBar placeholder={'Search for a space'} onChange={value => updateSearchValue(value)}/>
                    </div>

                    <div className={'space-list'}>
                       <>
                           {
                               spaces.length > 0 ?
                                   spaces.map((space, spaceIndex) => {
                                       return <SpaceLink space={space} key={spaceIndex}/>
                                   }) : <div className={'pre-loader'}>
                                           <div className={'loading-text'}>
                                           No spaces found. Try <Link to={'/'}>connecting</Link> with other users
                                           </div>
                                        </div>
                           }
                       </>

                    </div>
                </div>

            </>
        }

    </div>
}


export default ChatList
