import React, {FormEvent, useEffect, useRef, useState} from "react";
import "../css/components/auth/auth.scss";
import {BsArrowLeft} from "react-icons/bs";
import {
   authFieldType,
   registerRequestObject,
   registrationDataSample,
   registrationDataType,
   registrationRequestType, userDataType
} from "../app/redux/storeTypes";
import {baseApi, headers} from "../api/base";
import ErrorHandler from "../components/ErrorHandler";
import Spinner from "../components/Spinner";
import {AxiosRequestHeaders} from "axios";
import { useNavigate } from "react-router-dom";
import appStore from "../app/redux/store";
import {setUserData} from "../app/redux/actions";

const RegisterView = () => {
   let navigate = useNavigate();
   let registerFields:authFieldType[] = [
      {
         name: 'firstname',
         label: 'First name',
         value: '',
         placeholder: 'Your first name',
         hasError: false,
         type: 'text',
         halfWidth: true,
         errorText: ''
      },

      {
         name: 'lastname',
         label: 'Last name',
         value: '',
         placeholder: 'Your last name',
         hasError: false,
         type: 'text',
         halfWidth: true,
         errorText: ''
      },

      {
         name: 'email',
         label: 'Email',
         value: '',
         placeholder: 'Your email address',
         hasError: false,
         type: 'email',
         halfWidth: true,
         errorText: ''
      },

      {
         name: 'phone',
         label: 'Phone',
         value: '',
         placeholder: 'Phone number',
         hasError: false,
         type: 'text',
         halfWidth: true,
         errorText: ''
      },




      {
         name: 'address',
         label: 'Address',
         value: '',
         placeholder: 'Your address',
         hasError: false,
         type: 'text',
         halfWidth: false,
         errorText: ''
      },

      {
         name: 'password',
         label: 'Password',
         value: '',
         placeholder: 'Your password',
         hasError: false,
         type: 'password',
         halfWidth: false,
         errorText: ''
      },

      {
         name: 'password_confirmation',
         label: 'Confirm your password',
         value: '',
         placeholder: 'Confirm password',
         hasError: false,
         type: 'password',
         halfWidth: false,
         errorText: ''
      }
   ]


   const verificationField:authFieldType[] = [
      {
         name: 'code',
         placeholder: 'Enter 6-digit code here',
         value: '',
         type: 'text',
         hasError: false,
         errorText: '',
         success: false
      }
   ]

   interface codeSampleType {
      sendingCode: boolean,
      requestText: string
   }

   let codeRequestSample = {
      sendingCode: false,
      requestText: 'Resend code'
   }
   const [fields, updateFields] = useState<authFieldType[]>(registerFields);
   const [activeTab, setActiveTab] = useState<number>(0);
   const [verifyField, setVerifyField] = useState<authFieldType[]>(verificationField);
   const [loadingVerification, setLoadingVerification] = useState(false);
   const [requestError, setRequestError] = useState<string>('');
   const [sendingRequest, updateSendingRequest] = useState<boolean>(false);
   const [resendText, updateResendText] = useState<codeSampleType>(codeRequestSample);

   let registrationData = useRef<registrationDataType>(registrationDataSample);

   let resetTimeout = useRef<null | number>(null);

   useEffect(() => {
      let savedUser = appStore.getState().user

      if (Object.values(savedUser).length > 0) {
         navigate('/')
      }
   }, [navigate]);



   const setFieldValue = (value: string, index: number) : void => {
      let fieldData = [...fields];

      fieldData[index].value = value;
      fieldData[index].hasError = value === ''

      updateFields(fieldData)
   }

   const setVerificationField = (value: string) : void => {
      let fieldData = [...verifyField];
      fieldData[0].value = value;
      fieldData[0].hasError = value === '' || (value.length >= 6 && value !== registrationData.current.verification.code);

      if (fieldData[0].success) {
         fieldData[0].success = false
      }

      setVerifyField(fieldData)
   }

   function createRequestObject() {
      let postRequestObject:registrationRequestType = registerRequestObject;

      fields.forEach(field =>{
         postRequestObject[field.name as keyof registrationRequestType] = field.value
      })

      return postRequestObject;
   }

   const handleSubmit = (event: FormEvent) => {
      event.preventDefault();

      if (resetTimeout.current != null) {
         clearTimeout(resetTimeout.current)
      }

      setRequestError('');
      let fieldData = [...fields];
      fieldData = fieldData.map(field => {
         field.hasError = false;
         field.errorText = ''

         return field
      });

      updateFields(fieldData)


    //  console.log({ postRequestObject })

      updateSendingRequest(true)

      baseApi.post('validate/user', createRequestObject(), {
         headers,
         timeout: 30000
      }).then(response => {
         updateSendingRequest(false)
        // console.log({ 'Validation response': response.data })
         registrationData.current = response.data;

         setActiveTab(1)
      }).catch(error => {
         //  console.log({ error })
         updateSendingRequest(false)
         if (error.response) {
            if (error.response.data) {
               if (error.response.data.errors) {
                  let errorObject = error.response.data.errors;
                  let errorKeys = Object.keys(errorObject);

                  let currentFieldData = [...fields];

                  // console.log({ currentFieldData })

                  errorKeys.forEach(key => {
                     currentFieldData = currentFieldData.map(field => {
                        if (field.name === key) {
                           // console.log('Field:', field, key)
                           field.hasError = true;
                           field.errorText = errorObject[key].join(",");
                        }

                        return field
                     })
                  })

                  updateFields(currentFieldData)
               } else {
                  setRequestError('An unexpected error occurred')
               }
            } else {
               setRequestError('An unexpected error occurred')
            }
         } else {
            setRequestError(error.message)
         }

         resetTimeout.current = window.setTimeout(() => {
            setRequestError('')
         }, 3000)
      })

   /*  baseApi.post('register', postRequestObject, {
        headers,
        timeout: 30000
     }).then(response => {
       // console.log({ response })
        updateSendingRequest(false)

        interface responseType extends registrationDataType {
           message: string
        }
        let dataObject:responseType = response.data;

         registrationData.current = {
           userId: dataObject.userId,
           userToken: dataObject.userToken,
           verification: {
              code: dataObject.verification.code,
              issuedAt: dataObject.verification.issuedAt
           }
        }

      //  console.log('Registration data:', registrationData.current)

        setActiveTab(1)
     })*/
   }


   const resetResendText = () => {
      setTimeout(() => {

         let resendSample = {
            requestText: 'Resend code',
            sendingCode: false
         }
         updateResendText({...resendSample});
      }, 3000)
   }

   const resendCode = () => {
      interface codeRequestType {
         email: string,
         firstname: string
      }

      let requestObject:codeRequestType = {
         email: "",
         firstname: ""
      }

      fields.forEach(field => {
         if (field.name === 'email' || field.name === 'firstname') {
            requestObject[field.name as keyof codeRequestType] = field.value
         }
      })

      let requestHeaders:AxiosRequestHeaders = {
         'Accepts': 'application/json',
      }

      let resendTextClone = {...resendText};

      resendTextClone.sendingCode = true;
      resendTextClone.requestText = 'Sending you the code...';

      updateResendText(resendTextClone)

      baseApi.post('send-code', requestObject, {
         headers: requestHeaders
      })
          .then(response => {
           // console.log('Response for code resend:', response);

             resendTextClone = {
                sendingCode: false,
                requestText: 'Code resent'
             }
             updateResendText(resendTextClone)

             registrationData.current.verification = response.data
           //  console.log('Updated registration data:', registrationData);

             resetResendText()
          }).catch(() => {
           //  console.log({ error })

         resendTextClone = {
            sendingCode: false,
            requestText: 'Error sending code'
         }

            updateResendText(resendTextClone);

            resetResendText()
      })
   }

   const handleVerification = (event: FormEvent) : void => {
      event.preventDefault();
      let currentTime:number = new Date().getTime();
      let codeIssueTime:number = new Date(registrationData.current.verification.issuedAt).getTime()

      let timeZoneOffset:number = new Date().getTimezoneOffset()
      let timeDifference:number = Math.floor((currentTime - codeIssueTime) / (1000 * 60)) + timeZoneOffset;

     // console.log({ date: currentTime, issuedAt: codeIssueTime, timeDifference, timeZoneOffset })

      let verifyState:authFieldType[] = [...verifyField];

      if (timeDifference > 30) {
         verifyState[0].hasError = true;
         verifyState[0].errorText = 'This code has expired. Please request a new one';
      } else {


         let requestHeaders:AxiosRequestHeaders = {
            Accepts: 'application/json',
         }
         setLoadingVerification(true)

         baseApi.post('register', createRequestObject(), {
            headers: requestHeaders
         }).then(response => {
           // console.log({ response })
            verifyState[0].hasError = false;
            verifyState[0].errorText = '';
            verifyState[0].success = true;
            let userData:userDataType = {
               id: response.data.userId,
               token: response.data.userToken,
               active: true
            }

            localStorage.setItem('userData', JSON.stringify(userData));
            appStore.dispatch(setUserData(userData))


            setLoadingVerification(false)
            setVerifyField([...verifyState])
            navigate('/')

         }).catch(() => {

            verifyState[0].hasError = true;
            verifyState[0].errorText = 'Error verifying contact';
            verifyState[0].success = false;

            setLoadingVerification(false)
            setVerifyField([...verifyState])
         });
      }


   }


   return <div className={'auth-container register-container'}>
         <div className={'detailed-container'}>
            {
               activeTab === 0 ? <div>
                  <div className={'auth-header'}>Create your account</div>
                  <form method={'post'} action={''} className={'auth-section register-section'} onSubmit={event => handleSubmit(event)}>
                     {
                        fields.map((field, index) => {
                           return <div key={index} className={`input-control ${field.halfWidth ? 'half-width-control' : ''}`}>
                              <label>{ field.label }</label>
                              <input
                                  name={field.name}
                                  value={field.value}
                                  type={field.type}
                                  className={`${field.hasError ? 'input-error': ''}`}
                                  placeholder={field.placeholder}
                                  onChange={event => setFieldValue(event.target.value, index)}
                              />
                              { field.errorText ?  <div className={'error-text'}>{ field.errorText }</div> : null }
                           </div>
                        })
                     }
                     {
                        requestError ? <ErrorHandler text={requestError}/> : <></>
                     }

                     <div className={'button-container'}>
                        <button
                            type={'submit'}
                            className={'button button-regular'}
                            disabled={fields.filter(field => field.value === '').length > 0}
                        >
                           {
                              !sendingRequest ? <span>Create</span> : <Spinner size={15} color={'#ffffff'} thickness={2}/>
                           }
                        </button>
                     </div>
                  </form>
               </div> : <div className={'verification-section'}>
                  <div className={'section-header'}>
                     <BsArrowLeft className={'header-icon'} onClick={() => setActiveTab(0)}/>
                     <div className={'header-text'}>Just one more step!</div>
                  </div>

                  <form method={'post'} action={''} className={'auth-section'}>
                     <div className={'section-desc'}>
                        We sent a 6-digit code to your email address, please enter that code here
                     </div>

                     {
                        verifyField.map((field, index) => {
                           return <div className={'input-control'} key={index}>
                              <input
                                  name={field.name}
                                  value={field.value}
                                  placeholder={field.placeholder}
                                  type={field.type}
                                  className={`${field.hasError ? 'input-error' : field.success ? 'input-success': ''}`}
                                  onChange={event => setVerificationField(event.target.value)}
                              />
                           </div>
                        })
                     }
                     <div className={'button-container'}>
                        <button
                            type={'submit'}
                            className={'button button-transparent light-transparent'}
                            onClick={event => handleVerification(event)}
                            disabled={verifyField[0].value.length === 0 || verifyField[0].value !== registrationData.current.verification.code}
                        >
                           {
                           loadingVerification ? <Spinner size={14} color={'#f5f5f5'} thickness={2}/> : <span>Verify</span>
                        }
                        </button>
                     </div>

                     <div className={`code-resend text-center ${resendText.sendingCode ? 'faded' : ''}`} onClick={() => {
                        if (!resendText.sendingCode) {
                           resendCode()
                        }
                     }
                     }>
                        { resendText.requestText }
                     </div>
                  </form>
               </div>
            }
         </div>
   </div>
}

export default RegisterView;
