import React, {ChangeEvent, MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import "../../css/components/contacts/contacts.scss";
import ContactDetails from "./ContactDetails";
import {baseApi, baseURL} from "../../api/base";
import {BiError, BiSearchAlt} from "react-icons/bi";
import { AiFillStar} from "react-icons/ai";
import appStore from "../../app/redux/store";
import {addContact, clearContacts, setUserData, updateContact, updateFavoriteStatus} from "../../app/redux/actions";
import {contactType, contactSample, userDataType} from "../../app/redux/storeTypes";
import PositionPlaceholder from "../PositionPlaceholder";
import {debounce} from "lodash";
import parse from "html-react-parser";
import {useNavigate} from "react-router-dom";
import {AxiosRequestHeaders} from "axios";
import "../../css/components/search-bar.scss";
import SpaceImage from "../chats/SpaceImage";
import {
    addSpacesToStore,
    eventInterface,
    getContactIndex, getUserSpaces,
    listenForStatusChange,
    setUserStatus,
    updateMessageCount
} from "../../api/pusherFunctions";
import {manageFavorites} from "../../helpers/functions";
import app from "../../App";


interface contactGroupType {
    initial: string,
    contacts: contactType[]
}

const ContactList:React.FC = () => {
    let navigate = useNavigate();



    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
 /*   const [currentOffset] = useState<number>(0);
    const [resultLimit] = useState<number>(10);*/
  /*  const [loadProgress, setLoadProgress] = useState<number>(0);
    const [loadTotal] = useState<number>(requestTimeout);*/
    const [loadError, setLoadError] = useState<boolean>(false);
    const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
    const [initials, setInitials] = useState<Array<string>>([]);
    const [contacts, updateContacts] = useState<Array<contactGroupType>>([]);
    const [activeContact, setActiveContact] = useState<contactType>(contactSample);
    let contactDetailsRef = useRef<HTMLElement | null>(null);
    let [currentSearch, setCurrentSearch] = useState<string>("");


    interface contactFunctionReturnType {
        contactListData: contactGroupType[],
        initialList: Array<string>
    }

    function createContactList(list:Array<contactType>) : contactFunctionReturnType {
        let firstNames = list.map(contact => contact.firstname).sort();

        let initialList:Array<string> = [], contactListData:contactGroupType[] = [];
        firstNames.forEach(firstname => {
            let matchingContact = list.find(contact => contact.firstname === firstname)
            if (matchingContact) {
                if (initialList.indexOf(firstname[0]) === -1) {
                    initialList.push(firstname[0])
                }
            }
        });

        initialList.forEach(initial => {
            let listObject:contactGroupType = {
                initial,
                contacts: list.filter(contact => contact.firstname[0] === initial)
            }

            contactListData.push(listObject)
        })

        // console.log({ initialList, contactListData })

        return { contactListData, initialList }
    }

    useEffect(() => {
        window.addEventListener("scroll", function () {
            if (contactDetailsRef.current) {
                let fixedPositionElement = contactDetailsRef.current as Element;
                if (this.scrollY > 20) {
                    if (!fixedPositionElement.classList.contains("scroll-fixed")) {
                        fixedPositionElement.classList.add('scroll-fixed')
                    }
                } else {
                    if (fixedPositionElement.classList.contains("scroll-fixed")) {
                        fixedPositionElement.classList.remove('scroll-fixed')
                    }
                }
            }
        })

        updateMessageCount()

    }, [])

    let channel = listenForStatusChange();

    let activityEvent:string = 'user.status';

    let eventCallback = function (eventData: eventInterface) : void {
        let storeContacts = appStore.getState().contacts;

        let contactIndex = getContactIndex(eventData.user);

        if (contactIndex !== -1) {
          //  console.log({ eventData })
            let newContactData ={...storeContacts[contactIndex], active: eventData.active} ;
            appStore.dispatch(updateContact(contactIndex, newContactData))

            let updatedContactList = createContactList(appStore.getState().contacts).contactListData;

            updateContacts(updatedContactList);
        }
    }

    channel.bind(activityEvent, eventCallback);

   /* const updateContactList = debounce(() => {
        let contactData = createContactList(appStore.getState().contacts).contactListData;
        updateContacts([...contactData])
    }, 1500)*/

    //appStore.subscribe(() => updateContactList())

   /* const updateProgress = useCallback(() => {
        if (loadProgress < loadTotal) {
            setLoadProgress(loadProgress + 1000)
        }
    }, [loadProgress, loadTotal]);

    useEffect(() => {
        setTimeout(() => {
           updateProgress()
        }, 1000)
    }, [loadProgress, updateProgress])*/


    const fetchContacts = useCallback( () => {
        let user = appStore.getState().user as userDataType;
        let requestTimeout:number = 30000;

       if ("token" in user && user.token) {
           let requestHeaders:AxiosRequestHeaders = {
               Accepts: 'application/json',
               Authorization: `Bearer ${user.token}`

           }
           getUserSpaces(user.id, user.token).then(spaces => {
               addSpacesToStore(spaces.data)

               baseApi.get(`get/all/${user.id}`, {
                   headers: requestHeaders,
                   timeout: requestTimeout,
               }).then(response => {
                     console.log('Contact response:', response)

                   appStore.dispatch(clearContacts())
                   response.data.forEach((contact: contactType) => {
                       appStore.dispatch(addContact(contact))
                   })

                   let storeData = appStore.getState();
                   let valueData = createContactList(storeData.contacts)

                   updateContacts(valueData.contactListData)
                   setInitials([...valueData.initialList])
                   setLoadingContacts(false);

                   setUserStatus();

               }).catch(error => {
                   setLoadError(true);
                   console.log('Request error:', error)
               })
           }).catch(error => {
               setLoadError(true)
               console.log({ error })
           })

       }
        // eslint-disable-next-line
    }, [])

    useEffect(() =>{

        setLoadingContacts(true);
        let userData = localStorage.getItem('userData');

        if (userData == null) {
            navigate('/login')
        } else {
            appStore.dispatch(setUserData(JSON.parse(userData)))
            fetchContacts()
        }

    }, [fetchContacts, navigate])

    const debounceSearch = debounce((value: string) => {
        let searchValue = value.toLowerCase()
        function includesSearchValue(value: string, searchValue: string) : boolean {
            return value.toLowerCase().includes(searchValue)
        }

        let storeData = appStore.getState();
        let searchResults = storeData.contacts.filter(contact => includesSearchValue(`${ contact.firstname } ${ contact.lastname }`, searchValue));

        let resultData = createContactList(searchResults);
        updateContacts(resultData.contactListData)
        setInitials(resultData.initialList)

       // console.log(`Search value: ${ value }`)
        setCurrentSearch(value)

    }, 500)

    const pageContentRef = useRef<null | HTMLElement>(null)


    const handleTabChange = (tab: string) :void => {
        let storedState = appStore.getState();

      /*  if (window.scrollY > 20) {
            window.scrollTo({ top: 5, left: 0})
        }*/

        let matchingData;
        if (tab === 'favorites') {
            let favoriteList = storedState.contacts.filter(contact => contact.in_favorites);
            matchingData = createContactList(favoriteList);
        }

        if (tab === 'all') {
            matchingData = createContactList(storedState.contacts);
        }

        if (matchingData) {
            updateContacts(matchingData.contactListData)
            setInitials(matchingData.initialList)
        }


    }



    function displayDetails(contact: contactType) : void {
        if (window.innerWidth > 1024) {
            setActiveContact(contact)

            if (!detailsOpen) {
                setDetailsOpen(true)
            }
        } else {
            navigate(`/contacts/${contact.id}`)
        }

    }


    function handleFavoriteChange(inFavorites: boolean) : void {
        let contactId = null;

        appStore.getState().contacts.forEach((contact, index) => {
            if (contact.id === activeContact.id) {
                contactId = index
            }
        })

        if (contactId != null) {
            appStore.dispatch(updateFavoriteStatus(contactId, inFavorites));
            let listData = createContactList(appStore.getState().contacts);

            updateContacts([...listData.contactListData])
            setInitials([...listData.initialList]);

           // console.log({ contactId, activeContact, inFavorites })

            setActiveContact({...activeContact, in_favorites: inFavorites});

            let user = appStore.getState().user as userDataType;

            manageFavorites(user.id, activeContact.id, user.token)/*.then(response => {
                console.log(response)
            })*/
        }
    }

    return (
        <>
            {
                !loadError ? <div className={"page-width centered contact-list-container"}>
                    <div className={`list-content ${detailsOpen ? 'details-open' : 'details-closed'}`}>
                        <SearchBar onSearch={value => debounceSearch(value)} onTabChange={tab => handleTabChange(tab)}/>

                        <div className={'content-main'} ref={element => pageContentRef.current = element}>
                            {/*   <div className={'progress-section centered'}>
                          <ProgressBar current={loadProgress} total={loadTotal} progressText={'Fetching your contacts..'}/>
                      </div>*/}

                            {
                                loadingContacts ? <PositionPlaceholder number={15}/> : <ContactListItems detailsOpen={detailsOpen} searchValue={currentSearch} contacts={contacts} initials={initials} onContactSelect={contact => displayDetails(contact)}/>
                            }

                        </div>


                    </div>

                    <div className={`contact-details ${detailsOpen ? 'details-open' : 'details-closed'}`} ref={element => contactDetailsRef.current = element}>
                        { Object.values(activeContact).length > 0 && contacts.length > 0 ? <ContactDetails
                                                                                                    closable={true}
                                                                                                    contact={activeContact}
                                                                                                    closeTriggered={() => setDetailsOpen(false)}
                                                                                                    onFavoriteChange={inFavorites => handleFavoriteChange(inFavorites)}
                        /> : null}

                    </div>
                </div> : <div className={'error-section'}><BiError className={'error-icon'}/> An unexpected error occured</div>
            }
        </>
    )
}



interface listProps {
    contacts: contactGroupType[],
    onContactSelect: (contact: contactType) => void,
    initials: Array<string>,
    searchValue: string,
    detailsOpen: boolean
}
const ContactListItems:React.FC<listProps> = ({ contacts, onContactSelect, initials, searchValue, detailsOpen }) => {
    let contactElements = useRef<null | HTMLElement[]>([]);
    let initialsRef = useRef<null | HTMLElement[]>([]);
    let initialElementsRef = useRef<null | HTMLElement[]>([]);
    let initialsListRef = useRef<HTMLElement | null>(null);
    let listRef = useRef<HTMLElement | null>(null);


    const [resetList, setResetList] = useState(false);

    const resetActiveInitials = () => {
        initialElementsRef.current?.forEach(element => {
            if (element.classList.contains('active')) {
                element.classList.remove('active')
            }
        })
    }





    let observeInitials = useCallback(() => {
        let intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                // console.log({ entry: entry.target.innerHTML, intersecting: entry.isIntersecting })
                if (entry.isIntersecting) {

                    resetActiveInitials()

                    let entryText = entry.target.innerHTML;
                    let matchingElement = initialElementsRef.current?.find(element => element.innerHTML === entryText);

                    if (matchingElement) {
                        matchingElement.classList.add('active');
                    }


                }
            })
        }, {
            threshold: .8
        })

        initialsRef.current?.forEach(initialElement => {
            intersectionObserver.unobserve(initialElement);
            intersectionObserver.observe(initialElement)
        })
    }, [initialsRef])

    useEffect(() => {
        if (resetList){
            observeInitials()

            setTimeout(() => { setResetList(false)}, 100)
        }
    }, [resetList, observeInitials]);

    useEffect(() => {
        observeInitials()
    }, [observeInitials])

    useEffect(() => {
            initialsRef.current = [];
            initialElementsRef.current = [];

            setResetList(true)
    }, [contacts])


    type refType = MutableRefObject<HTMLElement[] | null>;
    type elementType = HTMLElement | null;

    const setRefs = (refs: refType, element: elementType) : void => {
        let elements = refs.current as HTMLElement[];
        if (element) {
            if (!elements.find(refElement => refElement.innerHTML === element.innerHTML)) {
                elements.push(element)
            }
        }
    }


    const scrollToPosition = (initial: string) : void => {
        let matchingInitial = initialsRef.current?.find(element => element.innerHTML === initial);

        if (matchingInitial) {
            resetActiveInitials();
            matchingInitial.classList.add('active');

            window.scrollTo({
                top: matchingInitial.offsetTop,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    type returnType = string
    function splitSearchValue(value: string, searchValue: string) :  returnType{
        let splitSearch = value.split("");
        let searchValueLength = searchValue.length;

        if (searchValueLength >= 2) {
            // @ts-ignore
            let matchingIndexes:Array<number> = [...value.matchAll(new RegExp(searchValue, 'gi'))].map(a => a.index);

            if (matchingIndexes.length > 0) {
                matchingIndexes.forEach(positionIndex => {
                    splitSearch.splice(positionIndex, 0, '<mark>');
                    splitSearch.splice(positionIndex+searchValueLength+1, 0, '</mark>')
                })
            }
        }

        let parsedValue:string = splitSearch.join('+').replace(/\+/g, "");

   /*     let parser = new DOMParser();
        let elementSpan = `<span>${parsedValue}</span>`;
        let htmlString = parser.parseFromString(elementSpan, 'text/html');
        let spanBody = htmlString.body.querySelector("span");*/


        return parsedValue;
    }


    return <div className={'contact-list'} ref={element => listRef.current = element}>
        { contacts.length > 0 ?  <div className={'contact-count'}>{contacts.length} contact{contacts.length > 1 ? <span>s</span> : null}</div> : null}
        {
         contacts.length > 0 ?  contacts.map((group, index) => {
                return <div className={'data-list-item'} key={index}>
                    <div className={'initial-section'} ref={element => setRefs(initialsRef, element)}>{ group.initial }</div>

                    <div className={'contact-group'}>
                        {
                            group.contacts.map((contact, contactIndex) => {
                                let contactName = `${ contact.firstname } ${ contact.lastname }`
                                return <div className={'contact-item flexbox align-center'} key={contactIndex} ref={element => setRefs(contactElements, element)}>
                                    <SpaceImage active={contact.active} src={`${baseURL}${contact.profile_picture}`}/>

                                    <div className={'contact-data'}>
                                        <div className={'contact-name'} onClick={() => onContactSelect(contact)}>
                                            { parse(splitSearchValue(contactName, searchValue))}
                                        </div>

                                    </div>


                                    {
                                        contact.in_favorites ? <AiFillStar className={'favorite-icon'}/> : null
                                    }


                                </div>
                            })
                        }
                    </div>


                </div>
            }): <div className={'no-contacts'}>No contacts found.</div>
        }

        <div className={'initials-list'} ref={element => {
            initialsListRef.current = element as HTMLElement
        }
        }
        >
            {
                initials.map((initial, initialIndex) => {
                    return <span key={initialIndex}
                                 ref={element => setRefs(initialElementsRef, element)}
                                 onClick={() => scrollToPosition(initial)}
                    >{ initial }</span>
                })
            }
        </div>


    </div>
}

interface searchProps {
    onSearch: (value: string) => void,
    onTabChange: (tab: string) => void
}

const SearchBar:React.FC<searchProps> = ({ onSearch, onTabChange })=> {
    let tabItemData:Array<string> = ['All', 'Favorites'];
    let searchBarContainer = useRef<null | HTMLElement>(null);
    const [searchValue, updateSearchValue] = useState<string>("");
    const [activeTab, setActiveTab] = useState<number>(0);
    const [tabItems] = useState<Array<string>>(tabItemData)

    useEffect(() =>{
        onTabChange(tabItems[activeTab].toLowerCase())

        // eslint-disable-next-line
    }, [activeTab, tabItems])



  /*  useEffect(() => {
        let contentElement = searchBarContainer.current as Element;

        /!*intersectionObserver.observe(contentElement)*!/

        window.addEventListener('scroll', function () {
            // console.log({ scrollTop: this.scrollY })

            if (this.scrollY > 20) {
                if (!contentElement.classList.contains('fixed-container')) {
                    contentElement.classList.add('fixed-container')
                }
            } else {
                if (contentElement.classList.contains('fixed-container')) {
                    contentElement.classList.remove('fixed-container')
                }
            }
        })
    }, [])*/

    return <div className={'search-bar-container'} ref={element => searchBarContainer.current = element}>
        <div className={'search-bar input-control centered'}>
            <BiSearchAlt className={'search-icon'}/>
            <input
                type={'text'}
                name={'search'}
                value={searchValue}
                key={'search-index'}
                placeholder={'Search contacts'}
                onChange={(event: ChangeEvent) =>{
                    let elementTarget = event.target as HTMLInputElement;
                    updateSearchValue(elementTarget.value)
                    onSearch(elementTarget.value)
                }
                }
            />
        </div>

        <div className={'contact-tabs flexbox align-center'}>
            {
                tabItems.map((item, itemIndex) => {
                    return <div key={itemIndex} className={`tab-item ${activeTab === itemIndex ? 'active-tab': ''}`} onClick={() =>  setActiveTab(itemIndex)}>
                        { item.toLowerCase() === 'favorites' ? <div className={'aligned-item'}><AiFillStar className={'favorite-icon'}/> Favorites</div> : <div>{item}</div>}
                        <span className={'border-line'}/>
                    </div>
                })
            }
        </div>
    </div>
}


export default ContactList
