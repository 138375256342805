import React from "react";
import "../css/components/file-downloader.scss"
import {AiFillFile} from "react-icons/ai";
import {HiOutlineDownload} from "react-icons/hi";
import {downloadFile} from "../helpers/functions";
import {getFileNameAndExtension} from "../helpers/mediaFunctions";
interface propTypes {
    src: string,
}
const FileDownloader:React.FC<propTypes> = ({ src }) => {
    return <div className={'file-downloader'}>
        <div className={'file-image'}>
            <AiFillFile className={'file-icon'}/>
            <div className={'file-extension'}>{ getFileNameAndExtension(src).file_extension }</div>
        </div>

        <div className={'file-row'}>
            <div className={'file-name'}>{ getFileNameAndExtension(src).file_name }</div>

            <div className={'download-icon'} onClick={() => downloadFile(src)}>
                <HiOutlineDownload/>
            </div>
        </div>
    </div>
}

export default FileDownloader
