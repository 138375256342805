import React, {useEffect, useRef, useState} from "react";
import "../css/components/video-player.scss";
import RangeSlider from "./RangeSlider";
import {debounce} from "lodash";
import {splitTime} from "../helpers/mediaFunctions";
import {FaPause, FaPlay} from "react-icons/fa";
import {BsFillGearFill, BsFullscreen} from "react-icons/bs";
import { AiOutlineFullscreenExit } from "react-icons/ai";
import Switch from "./Switch";

interface propTypes {
    src: string
}


const VideoPlayer:React.FC<propTypes> = ({ src }) => {
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [inFullScreen, setInFullScreen] = useState<boolean>(false);
    const [shouldLoop, setShouldLoopVideo] = useState<boolean>(false);
    let videoContainer = useRef<HTMLDivElement | null>(null);


    let videoElement = useRef<HTMLVideoElement | null>(null);
    let intervalTimeout = useRef<number>(0);
    const [videoInterval] = useState<number>(1000);


    useEffect(() => {
        if (currentTime > 0) {
            intervalTimeout.current = window.setTimeout(() => {
                let video = videoElement.current as HTMLVideoElement;

                let duration = Math.round(video.currentTime)
                setCurrentTime(duration)
            }, videoInterval)
        }
    }, [currentTime]);

    function togglePlay() : void {
        let video = videoElement.current as HTMLVideoElement;

            if (video.paused) {
                playVideo()
            } else{
                pauseVideo()
            }
    }

    function pauseVideo() : void {
        let video = videoElement.current as HTMLVideoElement;
            video.pause();

            if (intervalTimeout.current != null) {
                window.clearTimeout(intervalTimeout.current)
            }
            setIsPlaying(false)
    }

    function playVideo() : void {
        let video = videoElement.current as HTMLVideoElement;

            video.play()
            if (intervalTimeout.current != null) {
                window.clearTimeout(intervalTimeout.current);


                intervalTimeout.current = window.setTimeout(() => {
                    setCurrentTime(currentTime + 1)
                }, videoInterval)

            }

            setIsPlaying(true)
    }

    function setVideoDuration() : void {
        let video = videoElement.current as HTMLVideoElement;

            let videoDuration = Math.round(video.duration);
            setDuration(videoDuration)

    }

    function moveTime(time: number) : void {
        let video = videoElement.current as HTMLVideoElement;
        video.pause();

        video.currentTime = time;
        setCurrentTime(time)
        setIsPlaying(false)
    }

    const resumeVideo = debounce(() => {
        playVideo();

        setIsPlaying(true)
    }, 100);

    const setFullScreen = () : void => {
        let container = videoContainer.current as HTMLDivElement;

        if (container.requestFullscreen) {
            container.requestFullscreen()
        }
    }

    const exitFullScreen = () : void => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    }

    const toggleFullScreen = () : void => {
      if (!inFullScreen) {
          setFullScreen()
      } else {
          exitFullScreen()
      }

      setInFullScreen(!inFullScreen)
    }

    useEffect(() => {
        function handleFullScreenChange() {
            if (!document.fullscreenElement) {
                setInFullScreen(false)
            }
        }/*

        function handleKeyPress(e: KeyboardEvent) {
            let video = videoElement.current as HTMLVideoElement
            let keyCode = e.code.toLowerCase();

            console.log(keyCode)
            if (keyCode === 'space') {
                if (video) {
                    if (video.paused) {
                        playVideo()
                    }  else {
                        resumeVideo()
                    }
                }
            }
/!*
            if (keyCode === 'arrowright' || keyCode == 'arrowleft') {
                pauseVideo();

                let current;
                if (keyCode === 'arrowright') {
                    video.currentTime += 10;
                    current = currentTime + 10;
                } else {
                    video.currentTime -= 10;
                    current = currentTime - 10;
                }
                setCurrentTime(current)

                playVideo()
            }*!/
        }*/

        document.removeEventListener('fullscreenchange', handleFullScreenChange)
        document.addEventListener("fullscreenchange", handleFullScreenChange);

       /* document.removeEventListener("keypress", handleKeyPress);
        document.addEventListener("keypress", handleKeyPress);*/
    }, [])

     const loopVideo = debounce((loop: boolean) => {
        if (loop) {
            let video = videoElement.current as HTMLVideoElement;

            if (video) {
                video.currentTime = 0;

                playVideo()
            }
        }

     }, 500)
    return <div className={'video-player'} ref={element => videoContainer.current = element}>
        <video src={src}
               onLoadedData={() => setVideoDuration()}
               ref={element => videoElement.current = element}
               onPlay={() => playVideo()}
               onPause={() => pauseVideo()}
               onEnded={() =>  loopVideo(shouldLoop)}
        >
            <source src={src} type={'video/mp4'}/>
            <source src={src} type={'video/ogg'}/>

            Your browser does not support video
        </video>

        {
            duration > 0 ? <div className={'video-controls'}>
                <div className={'control-row'}>
                   <div className={'range-row'}>
                       <RangeSlider
                           min={0}
                           max={duration}
                           value={currentTime}
                           onChange={value => moveTime(value)}
                           onSelect={() => resumeVideo()}/>
                   </div>
                    <div className={'video-duration flexbox space-between'}>
                        <span>{ currentTime >= 0 ? splitTime( currentTime ) : null}</span>
                        <span>{ currentTime > 0 ? "-" : "" }{ splitTime( duration - currentTime )}</span>
                    </div>
                </div>

                <div className={'lower-row flexbox space-between'}>
                    <VideoSettings
                        video={videoElement.current as HTMLVideoElement}
                        onLoop={(loop) => setShouldLoopVideo(loop) }
                    />


                    <div className={'play-toggle'} onClick={() => togglePlay()}>
                        { !isPlaying ? <FaPlay/> : <FaPause/>}
                    </div>

                    <div className={'fullscreen-toggle'} onClick={() => toggleFullScreen()}>
                        {
                            inFullScreen ? <AiOutlineFullscreenExit/> : <BsFullscreen/>
                        }
                    </div>
                </div>
            </div> : null
        }
    </div>
}
interface optionTypes {
    optionName: string,
    type: "switch" | "list"
    options: Array<string | number> | boolean,
    handler?: (value : any) => void
}


interface settingTypes {
    video: HTMLVideoElement,
    onLoop?: (loop: boolean) => void
}
const VideoSettings:React.FC<settingTypes> = ({ video, onLoop }) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [loop, setLoop] = useState(false);

    useEffect(() => {

       function handleOutsideClick(e : Event) {
            let target = e.target as HTMLElement;
            if (target.closest(".settings-section") == null) {
                setShowOptions(false)
            }
        }

        console.log('Loop changed to:', loop)

        document.removeEventListener('click', handleOutsideClick)
        document.addEventListener("click", handleOutsideClick)
    }, [])

    useEffect(() => {
        if (onLoop) {
            onLoop(loop)
        }
    }, [loop])

    function setPlaybackRate(rate: number) {

        video.playbackRate = rate
    }

    let videoOptions:optionTypes[] = [
        {
            optionName: "Playback speed",
            type: "list",
            options: [0.25, 0.5, .75, 1, 1.25, 1.5],
            handler: (rate: number) => setPlaybackRate(rate)
        },

        {
            optionName: "Loop",
            type: "switch",
            options: loop,
            handler: (state: boolean) => setLoop(state)
        }
    ]

    const [options] = useState<optionTypes[]>(videoOptions);




    return  <div className={'settings-section'}>
        <BsFillGearFill className={'gear-icon'} onClick={() => setShowOptions(!showOptions)}/>
        {
             <ul className={'option-list'} style={{ display: `${showOptions ? 'block' : 'none'}`}}>
                {
                    options.map((option, index) => {
                        return <li key={index} className={`${option.type === 'switch' ? 'switch' : ''}`}>
                            { option.optionName }
                            {
                                option.type === 'list' && typeof option.options !== "boolean" && option.options.length > 0 ?   <ul className={'data-list'}>
                                    {
                                        option.options.map((optionItem, optionIndex) => {
                                            return <li key={optionIndex} onClick={() =>  {
                                                if (option.handler) {
                                                    option.handler(optionItem)
                                                }
                                            }
                                            }>
                                                { optionItem }{option.optionName.toLowerCase() === 'playback speed' ? 'x' : ''}
                                            </li>
                                        })
                                    }
                                </ul> : null
                            }

                            {
                                option.type === 'switch' && typeof option.options === 'boolean' ?
                                    <Switch checked={option.options} onSwitch={state => {
                                        if (option.handler) {
                                            option.handler(state)
                                        }
                                    }}/> : null
                            }
                        </li>
                    })
                }
            </ul>
        }
    </div>
}

export default VideoPlayer
