import React, {useEffect, useState} from "react";
import ChatList from "../components/chats/ChatList";
import "../css/components/chats/view.scss";
import ChatSpace from "../components/chats/ChatSpace";
import { eventInterface, getSpacePosition, listenForStatusChange, pusher} from "../api/pusherFunctions";
import appStore from "../app/redux/store";
import {updateSpaceMember} from "../app/redux/actions";
import {contactType} from "../app/redux/storeTypes";
import { useParams } from "react-router-dom";


const ChatView:React.FC = () => {
    let parameters = useParams();
    let notifChannel = listenForStatusChange();
    const [refreshList, setRefreshList] = useState(false);
    const [member, updateMember] = useState<contactType | {}>({})
    useEffect(() => {
        if (refreshList) {
            setRefreshList(false)
        }
    }, [refreshList])



    let eventType:string = "user.status";

    let eventCallback = (eventData: eventInterface) : void => {
        let storeUser = appStore.getState().user;

       //  console.log({ spaceEventData: eventData, memberId: member})

        if ("id" in storeUser && storeUser) {
            let spaceData = getSpacePosition(eventData);

            if (spaceData) {
                if (spaceData.spaceIndex !== -1 && spaceData.memberIndex !== -1) {
                    appStore.dispatch(updateSpaceMember(spaceData.spaceIndex, spaceData.memberIndex, spaceData.member));

                    setRefreshList(true)
                    if (eventData.user !== storeUser.id) {
                        updateMember({...spaceData.member})
                    }

                }
            }
        }
    }

    notifChannel.bind(eventType, eventCallback);

    return <div className={'space-view'}>
        <ChatList refresh={refreshList}/>
        <ChatSpace updatedMember={member as contactType} onMessageUpdate={() => setRefreshList(true)}/>
    </div>
}

export default ChatView
