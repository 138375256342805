import React, {useEffect, useRef, useState} from "react";
import "../css/components/media-view.scss";
import {FaMicrophone, FaPlay} from "react-icons/fa";
import "../css/components/voice-recorder.scss";
import {AiFillPauseCircle, AiTwotoneStop} from "react-icons/ai";

import {GrClose} from "react-icons/gr";

interface propTypes {
    onRecordEnd: (audio: File) => void
}
const VoiceRecorder:React.FC<propTypes> = ({ onRecordEnd }) => {
    let recorder = useRef<MediaRecorder | null>(null);
    let chunks = useRef<Array<any>>([]);

    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [recorderPaused, setRecorderPaused] = useState<boolean>(false);
    const [recordingEnded, setRecordingEnded] = useState(false);
    const [recordingCancelled, setRecordingCancelled] = useState(false);

    useEffect(() => {
        if (navigator.mediaDevices) {
           // console.log('Voice record supported');

            const mediaConstraints = { audio: true }

            navigator.mediaDevices.getUserMedia(mediaConstraints).then(stream => {
              //  console.log({ stream })
                recorder.current = new MediaRecorder(stream)
            })

        }

        document.addEventListener("click", function (e) {
            let target = e.target as HTMLElement;
            if (target.closest(".media-recorder") == null && isRecording) {
                setIsRecording(false)
            }
        })
    }, [])

    function pauseRecording() {
        setRecorderPaused(true);
        if (recorder.current != null) {
          //  console.log({ recorderStateAfterPause: recorder.current.state })
            recorder.current.pause()
        }
    }

    function resumeRecording() {
        setRecorderPaused(false)
        if (recorder.current != null) {
           // console.log({ recorderStateAfterResume: recorder.current.state })
            recorder.current.resume()
        }
    }

    function stopRecording() {
        if (recorder.current != null) {
           // console.log({ recorderStateAfterStop: recorder.current.state })
            recorder.current?.stop()

            setIsRecording(false)

           // console.log({ chunks })
            setRecordingEnded(true)
        }
    }

    function cancelRecording() {
        if (recorder.current != null) {
            setRecordingCancelled(true)
            recorder.current?.stop();

           setIsRecording(false);
           setRecordingEnded(true)
        }
    }

    useEffect(() => {
        if (recordingCancelled) {

            setTimeout(() => {
                setRecordingCancelled(false)
            }, 1000)
        }
    }, [recordingCancelled])

    useEffect(() => {
        if (recorder.current != null) {
           // console.log('Recorder available')
            recorder.current.ondataavailable = function (e) {
                chunks.current.push(e.data);
                let audioFile = new File([e.data], `voice-recording-${Date.now()}.mpeg`,
                    { type: 'audio/mp3'}
                )
             //  console.log({ recorderData: audioFile});

                if (!recordingCancelled) {
                    onRecordEnd(audioFile)
                }
            }
        }
    }, [recorder.current, recordingCancelled])
    function handleRecord() {
        if (recorder.current !== null && !isRecording) {
          //  console.log({ recorderStateOnStart: recorder.current.state })
            recorder.current.start()
        }
        setRecordingEnded(false)
        setIsRecording(true)
    }

    return <>
        <div className={'media-recorder'}>
            <div className={`media-options ${isRecording ? 'open' : 'closed'}`}>
                <div className={'option-items'}>
                    {
                        !recorderPaused ?  <AiFillPauseCircle onClick={() => pauseRecording()} className={'recorder-option pause-option'} />
                            : <FaPlay onClick={() => resumeRecording()} className={'recorder-option play-option'}/>
                    }
                    <AiTwotoneStop className={'recorder-option stop-option'} onClick={() => stopRecording()} />
                    <GrClose className={'recorder-option cancel-option'} onClick={() => cancelRecording()}/>
                </div>
            </div>
           <div className={`mic-holder ${isRecording ? 'animate-recording' : ''}`}>
               <FaMicrophone className={'microphone'} onClick={() => handleRecord()} />
           </div>
        </div>
    </>
}

export default VoiceRecorder
