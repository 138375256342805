import React from "react";
import "../../css/components/contacts/contact-placeholder.scss";

const ContactPlaceholder = () => {
    return <div className={'contact-container'}>
            <div className={'image-placeholder'}/>
        <div className={'name-placeholder'}/>

        <div className={'address-placeholder'}/>


        <div className={'details-placeholder'}>
            {
                Array(2).fill('').map((item, index) => {
                    return <div key={index} className={'item-placeholder'}>
                        <div className={'icon-placeholder'}/>

                        <div className={'list-placeholder'}>
                            <div className={'list-main-placeholder'}/>
                            <div className={'list-sub-placeholder'}/>
                        </div>

                        <div className={'icon-placeholder contact-icon'}/>
                    </div>
                })
            }



        </div>
    </div>
}

export default ContactPlaceholder
