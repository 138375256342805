import React, {ChangeEvent, useState} from "react";
import "../css/components/search-bar.scss"
import {BiSearchAlt} from "react-icons/bi";

interface propTypes {
    placeholder: string,
    onChange: (value: string) => void
}
const SearchBar:React.FC<propTypes> = ({ placeholder, onChange }) => {
    const [searchValue, updateSearchValue] = useState<string>('');

    function handleChange(event: ChangeEvent) : void {
        let target = event.target as HTMLInputElement;

        updateSearchValue(target.value)
        onChange(target.value)
    }
    return <div className={'search-bar-container'}>
        <div className={'search-bar input-control'}>
            <BiSearchAlt className={'search-icon'}/>

            <input
                type={'text'}
                value={searchValue}
                name={'search'}
                placeholder={placeholder}
                onChange={event => handleChange(event)}
            />
        </div>
    </div>
}

export default SearchBar
