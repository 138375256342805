import React from "react";
import "../css/components/range-slider.scss"

interface propTypes {
    min: number,
    max: number,
    value: number,
    onChange: (value: number) => void
    onSelect: () => void
}
const RangeSlider:React.FC<propTypes> = ({ min, max, value, onChange, onSelect}) => {
    return <div className={"duration-slider"}>
        <input
            type={"range"}
            min={min}
            max={ max }
            value={value}
            className={"input-range"}
            onClick={() => onSelect()}
            onChange={(event) => {
                onChange(parseInt((event.target as HTMLInputElement).value))
            }}
        />
        <div className={"position-indicator"} style={{
            width: `${(value / max) * 100}%`
        }}/>
</div>
}

export default RangeSlider
