import {getFileNameAndExtension} from "./mediaFunctions";
import axios, {AxiosRequestHeaders} from "axios";
import appStore from "../app/redux/store";
import {baseApi, baseURL} from "../api/base";

export const downloadFile = (source: string, fileName = `${ Date.now()}`) : void => {
    let link = document.createElement("a");

    link.target = "_blank";

    let fileData = getFileNameAndExtension(source);
    let name:string = `${fileData.file_name}`;
    link.href =`${ baseURL }download/file/${encodeURI(name)}`;
    link.download =  fileName;
    document.body.appendChild(link);
    link.click();


    document.body.removeChild(link);



  /* let fileData = getFileNameAndExtension(source);

    let fileName:string = `${fileData.file_extension}/${fileData.file_name}`;

    let user = appStore.getState().user;

    if ("id" in user && user) {
        baseApi.post('download/file', { file: fileName}, {
            headers: {
                Accepts: 'application/json',
                Authorization: `Bearer ${ user.token }`
            }
        }).then(response => {
            console.log({ response })

        })
    }*/


}

export const manageFavorites = async (userId: number, contactId: number, token: string) => {
    let requestData = {
        id: userId,
        contact_id: contactId
    }

    let requestHeaders:AxiosRequestHeaders = {
        Accepts: 'application/json',
        Authorization: `Bearer ${token}`
    }

    return await baseApi.post('set/favorites', requestData, {
        headers: requestHeaders
    })
}
