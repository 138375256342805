import React, {useEffect, useState} from "react";
import "../../css/components/contacts/contact-details.scss"
import { GrClose} from "react-icons/gr";
// import { IoEllipsisVertical } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ImagePlaceholder from "../ImagePlaceholder";
import { FaPhoneAlt } from 'react-icons/fa';
import { RiWechatLine } from "react-icons/ri";
import {AiFillExclamationCircle, AiFillStar, AiOutlineMail, AiOutlineStar} from "react-icons/ai";
import { SiMinutemailer } from "react-icons/si";
import {contactType, userDataType} from "../../app/redux/storeTypes";
import SpaceImage from "../chats/SpaceImage";
import appStore from "../../app/redux/store";
import {createSpace} from "../../api/pusherFunctions";
import Spinner from "../Spinner";
import {baseURL} from "../../api/base";

interface propTypes {
    closable: boolean,
    closeTriggered?: () => void,
    contact: contactType,
    onFavoriteChange: (inFavorites: boolean) => void
}


const ContactDetails:React.FC<propTypes> = ({ closable, closeTriggered , contact, onFavoriteChange}) => {
    let navigate = useNavigate();
    const [creatingSpace, setCreatingSpace] = useState<boolean>(false);
    const [spaceError, setSpaceError] = useState<boolean>(false);


    useEffect(() => {
        if (spaceError) {
            setTimeout(() => {
                setSpaceError(false)
            }, 2000)
        }
    }, [spaceError])

    function navigateToSpace() : void {
        setCreatingSpace(true);
        setSpaceError(false)
       createSpace(contact.id).then(response => {
            if (response) {
                setCreatingSpace(false)
               // console.log( { response })
                let spaceId:string = response.data.space.space;
                navigate(`/space/${spaceId}`)
            }
        }).catch(() => {
            setSpaceError(true)
            setCreatingSpace(false)
        })
    }

    return <div className={'detailed-container'}>
        {
            closable ? <div className={'detail-header flexbox align-center space-between'}>
              {/*  <div className={'contact-options'}>
                    <IoEllipsisVertical className={'vertical-icons'}/>
                </div>*/}

                <div className={'close-details'} onClick={() => {
                    if (closeTriggered) {
                        closeTriggered()
                    }

                }
                }>
                    <GrClose className={'close-icon'}/>
                </div>
            </div> : null
        }

        <div className={'image-section '}>
            {/* !contact.profile_picture ? <ImagePlaceholder/> : <img src={contact.profile_picture}/>*/}
            <SpaceImage active={contact.active} src={ `${baseURL}${contact.profile_picture}` }/>
        </div>


        <div className={'contact-name'}>{ contact.firstname } { contact.lastname }</div>

        <div className={'contact-address'}>{ contact.address }</div>

        <div className={'contact-section'}>
            <div className={'contact-section-item flexbox space-between align-center'}>
                <div className={'section-data flexbox align-center'}>
                    <FaPhoneAlt className={'data-icon'}/>
                    <div className={'data-list'}>
                        <div className={'data-value'}>{ contact.phone }</div>
                        <div className={'data-label'}>Phone</div>
                    </div>
            </div>


                <div className={'section-actions flexbox align-center'}>
                    <div className={'action-icon'}>
                        <a href={`tel: ${contact.phone}`}><FaPhoneAlt className={'icon'}/></a>
                    </div>

                    {
                     contact.id !== (appStore.getState().user as userDataType).id  ? <div className={'action-icon'} onClick={() => navigateToSpace()}>
                         <>
                             {
                                 spaceError ?  <AiFillExclamationCircle className={'icon error-icon'}/> : <>

                                     {
                                         !creatingSpace ?  <RiWechatLine className={'icon chat-icon'}/> :
                                             <Spinner size={16} color={'#c10060'} thickness={2}/>
                                     }
                                 </>
                             }
                         </>
                            </div> : null
                    }
                </div>
        </div>

            <div className={'contact-section-item flexbox space-between align-center'}>
                <div className={'section-data flexbox align-center'}>
                    <AiOutlineMail className={'data-icon'}/>
                    <div className={'data-list'}>
                        <div className={'data-value'}>{ contact.email }</div>
                        <div className={'data-label'}>Email</div>
                    </div>
                </div>


                <div className={'section-actions flexbox align-center'}>
                    <a href={`mailto:${contact.email}`} className={'action-icon'}>
                        <SiMinutemailer className={'icon'}/>
                    </a>
                </div>
            </div>

            {
                "user" in appStore.getState() ? <>
                {
                    ( appStore.getState().user as userDataType).id !== contact.id ?   <div className={'favorite-section'}>
                        {
                            !contact.in_favorites ?   <AiOutlineStar className={'favorite-icon star-empty'} onClick={() => onFavoriteChange(true)}/> :
                                <AiFillStar className={'favorite-icon star-filled'} onClick={() => onFavoriteChange(false)}/>
                        }
                        <div className={'favorite-label'}>
                            { contact.in_favorites ? "Remove from" : "Add to"} favorites
                        </div>
                    </div> : null
                }
                </> : null
            }
    </div>
    </div>
}


export default ContactDetails
