import React from "react";
import {RiUserLine} from "react-icons/ri";
import "../css/components/image-placeholder.scss";

const ImagePlaceholder:React.FC = () => {
    return <div className={'contact-image'}>
        <RiUserLine className={'contact-icon'}/>
    </div>
}

export default ImagePlaceholder;