import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import "../css/components/profile/profile-view.scss";
import appStore from "../app/redux/store";
import {useNavigate} from "react-router-dom";
import SpaceImage from "../components/chats/SpaceImage";
import {authFieldType, contactType, feedbackType, userDataType} from "../app/redux/storeTypes";
import {IoCameraOutline} from "react-icons/io5";
import SliderFeedback from "../components/SliderFeedback";
import {baseApi, baseURL} from "../api/base";
import Spinner from "../components/Spinner";
import {pictureTypes} from "../helpers/mediaFunctions";

interface fieldGroupType {
    groupLabel: string,
    fields: authFieldType[]
}
interface pageType {
    profile_picture?: string | File,
    phone?: string,
    address?: string,
    password?: string,
    password_confirmation?: string
}

interface userDetailType {
    name: string,
    phone: string,
    address: string,
    email: string,
    active: boolean
}

const ProfileView:React.FC = () => {
    let userDetails:userDetailType = {
        name: "Samuel Kuteyi",
        phone: "+2348053730806",
        address: "321A , Dellaware View, Copenhagen, Denmark",
        email: "kuteyisamueldev@gmail.com",
        active: true
    }

    let fieldGroups:fieldGroupType[] = [
        {
            groupLabel: "Contact information",
            fields: [
                {
                    name: 'phone',
                    label: 'Phone',
                    value: '',
                    placeholder: 'Phone number',
                    hasError: false,
                    type: 'text',
                    halfWidth: true,
                    errorText: ''
                },

                {
                    name: 'address',
                    label: 'Address',
                    value: '',
                    placeholder: 'Your address',
                    hasError: false,
                    type: 'text',
                    halfWidth: true,
                    errorText: ''
                },
            ]
        },
        {
            groupLabel: "Update password",
            fields: [
                {
                    name: 'password',
                    label: 'Password',
                    value: '',
                    placeholder: 'New password',
                    hasError: false,
                    type: 'password',
                    halfWidth: true,
                    errorText: ''
                },

                {
                    name: 'password_confirmation',
                    label: 'Confirm new password',
                    value: '',
                    placeholder: 'Confirm password',
                    hasError: false,
                    type: 'password',
                    halfWidth: true,
                    errorText: ''
                }
            ]
        }
    ]

    let feedbackSample:feedbackType = {
        type: "success",
        open: false,
        text: "Account info updated"
    }


    const [user, updateUser] = useState<userDetailType>(userDetails);
    const [profilePicture, updateProfilePicture] = useState<File | string>("");
    const [fieldItems, setFieldItems] = useState<fieldGroupType[]>(fieldGroups);
    const [feedback, updateFeedback] = useState<feedbackType>(feedbackSample);
    const [fetchingProfile, setFetchingProfile] = useState<boolean>(false);
    const [loadError, setLoadError] = useState<boolean>(false);
    const [tempUrl, setTempUrl] = useState<string>("");
    const [updatingContact, setUpdatingContact] = useState<boolean>(false);
    const [initPageState, setInitPageState] = useState<pageType>({});
    const [updatedPageState, setUpdatedPageState] = useState<pageType>({});
    let navigate = useNavigate();

    function loadProfile() {
        let user = appStore.getState().user as userDataType;


        if ("id" in user && user) {
            setFetchingProfile(true)
            setLoadError(false);

            baseApi.get(`/get/${user.id}`, {
                timeout: 30000
            }).then(response => {
                let data:contactType = response.data;

             //   console.log({ data })

                let detailItem:userDetailType = {...userDetails};
                let pageState:pageType = {}
                detailItem['name'] = `${data.firstname} ${data.lastname}`
                 let valueKeys = Object.keys(data), detailKeys = Object.keys(detailItem);

                valueKeys.forEach(key => {
                    if (detailKeys.indexOf(key) !== -1) {
                        let keyData = key as keyof userDetailType;
                        // @ts-ignore
                        detailItem[keyData] = data[keyData]

                    }
                })

                let fieldData = [...fieldItems];
                fieldData = fieldData.map(group => {
                    group.fields.map(field => {
                        if (valueKeys.indexOf(field.name) !== -1) {
                            field.value = data[field.name as keyof contactType] as string
                            // @ts-ignore
                            pageState[field.name as keyof pageType] = data[field.name]
                        }

                        return field
                    })
                    return group
                })

                updateUser({...detailItem});
                setFieldItems([...fieldData]);
                if (data.profile_picture !== '') {
                    setTempUrl(`${baseURL}${data.profile_picture}`);
                }

                pageState['profile_picture'] = `${baseURL}${data.profile_picture}`;
                pageState['password'] = '';
                pageState['password_confirmation'] = '';

               // console.log({ pageState })

                setInitPageState({...pageState})
                setUpdatedPageState({...pageState})


                setFetchingProfile(false);

            }).catch(error => {
                console.log({ error })

                setLoadError(true)
            })
        }
    }
    useEffect(() => {
     /*   let userData = appStore.getState().user as userDataType;
        console.log({ user })
        updateUser(userData)*/


        if (localStorage.getItem("userData") == null) {
            navigate('/login')
        } else {
            loadProfile()
        }

       /* setTimeout(() => {
            updateFeedback({...feedback, open: true})
        }, 1500)*/
    }, [])

    useEffect(() => {
        if (feedback.open) {
            setTimeout(() => {
                updateFeedback({...feedback, open: false})
            }, 5000)
        }
    }, [feedback])

  /*  useEffect(() => {
        console.log({ changedFields: changedFields()})
    }, [updatedPageState])*/

    function changedFields() :number {
        let updatedKeys = Object.keys(updatedPageState);

        return updatedKeys.filter(key => initPageState[key as keyof pageType] !== updatedPageState[key as keyof pageType]).length
    }

    function setValue(groupIndex : number, index: number, value: string) : void {
        let fieldClone = [...fieldItems];
        let fieldData = fieldClone[groupIndex].fields[index];
        fieldData.value = value;

        let stateObject:any = {}
        stateObject[fieldData.name] = fieldData.value
        setUpdatedPageState({...updatedPageState, ...stateObject})
        setFieldItems(fieldClone)
    }

    function handleUpload(event: ChangeEvent) {
        let target = event.target as HTMLInputElement;

        if (target.files) {
            console.log('File:', target.files[0])
            let file = target.files[0];
            const maxUploadSize = 5;

            let fileSize =  file.size / 1000000;
            let feedbackData:feedbackType = {
                type: "error",
                text: "",
                open: true
            }

                let dotIndex = file.name.lastIndexOf(".");
                let extension = file.name.slice(dotIndex+1, file.name.length);

                console.log(extension)

                if (pictureTypes.indexOf(extension) === -1) {
                    feedbackData['text'] = 'File type not supported';
                    updateFeedback({...feedbackData})
                } else {
                    if (fileSize > 5) {
                        feedbackData['text'] = `Max upload size is ${maxUploadSize}MB`;
                        updateFeedback({...feedbackData})
                    } else {
                        let blob = new Blob([file])
                        let blobUrl = URL.createObjectURL(blob);

                        setTempUrl(blobUrl)
                        updateProfilePicture(file)

                        let stateObject = {
                            profile_picture: blobUrl
                        }

                        setUpdatedPageState({...updatedPageState, ...stateObject})

                        console.log({ extension })
                    }

                }



        }

    }


    function updateInfo() : void {
        interface requestType extends pageType{
            id: string
        }

        let storeUser = appStore.getState().user as userDataType;

        let requestObject:requestType = {
            id: storeUser.id.toString()
        };

        if (profilePicture != null) {
            requestObject['profile_picture'] = profilePicture
        }

        fieldItems.forEach(group => {
            group.fields.forEach(field => {
                if (field.value !== '') {
                    let key = field.name as keyof requestType;

                    requestObject[key] = field.value
                }
            })
        })


        console.log({ requestObject })


        let formData = new FormData();

        for (let key in requestObject) {
            const data = requestObject[key as keyof requestType];
            if (data) {
                console.log({ data })
                formData.append(key, data)
            }

        }


        setUpdatingContact(true)

        baseApi.post(`update/contact`, formData, {
            timeout: 30000,
            headers: {
                Accepts: `application/json`,
                Authorization: `Bearer ${storeUser.token}`
            }
        }).then(response => {
            console.log({ response })
            let data = response.data;
            setUpdatingContact(false)

            setInitPageState({...updatedPageState})
            updateUser({...user, ...{address: data.address, phone: data.phone}})
            updateFeedback({...feedback, type: "success", text: "Your profile has been updated!", open: true})
        }).catch(error => {
            console.log({ error })
            setUpdatingContact(false)
            let errorText = "Error updating information.";

            if (error.response.data) {
                errorText = `${ errorText } ${ error.response.data.message }`
            } else {
                errorText = `${errorText} ${error.message }`
            }


            updateFeedback({...feedback, type: 'error', text: errorText, open: true })
        })
    }

    const handleSubmit = (event: FormEvent) : void => {
        event.preventDefault();

        updateInfo();
    }
    const discardChanges = () : void => {
        let fieldData = [...fieldItems];
        for (let key in initPageState) {
            let keyValue = initPageState[key as keyof pageType]
            if (key === 'profile_picture') {
                setTempUrl(keyValue as string)
            }else{
               fieldData = fieldData.map(group => {
                   group.fields.map(field => {
                       if (field.name === key) {
                           field.value = keyValue as string
                       }
                       return field;
                   })
                   return group
               })
            }
        }

        setFieldItems([...fieldData]);
        setUpdatedPageState({...initPageState});
    }

    return <div className={'profile-view-container container container-md centered'}>
        {
            loadError  || fetchingProfile ? <>
            {
               <div className={'load-holder'}>
                  {
                      fetchingProfile ? <Spinner size={18} color={'#f2f2f2'} thickness={2}/>
                          : <div className={'load-error'}>Error fetching profile. <span onClick={() => loadProfile()}>Retry</span></div>
                  }

                </div>
            }
            </> : <>
                <div className={'header-section'}>
                    <div className={'profile-image-container'}>
                        <SpaceImage active={user.active} src={tempUrl}/>

                        <div className={'image-picker-section'}>
                            <input type={'file'} name={'profile-image'} id={'profilePictureFile'} onChange={event => handleUpload(event)}/>
                            <label htmlFor={'profilePictureFile'}>
                                <IoCameraOutline/>
                            </label>
                        </div>
                    </div>

                    <div className={'user-details'}>
                        <div className={'user-name'}>{ user.name }</div>
                        <div className={'phone'}>{ user.phone }</div>
                        <div className={'address'}>{ user.address }</div>
                        <div className={'email'}>{ user.email }</div>
                    </div>

                </div>

                <div className={'edit-section'}>
                    <header className={'edit-header'}>Edit profile</header>
                    <form className={'update-form'} method={'post'} onSubmit={event => handleSubmit(event)}>
                        {
                            fieldItems.map((group, groupIndex) => {
                                return <div className={'field-group'} key={groupIndex}>
                                    <header>{ group.groupLabel }</header>
                                    <div className={'group-data flexbox space-between'}>
                                        {
                                            group.fields.map((field, index) => {
                                                return <div
                                                    className={
                                                        `input-control ${field.halfWidth ? 'half-width' : ''}`} key={index}>
                                                    { field.label ? <label>{ field.label }</label> : null }

                                                    <input
                                                        type={field.type}
                                                        name={field.name}
                                                        placeholder={field.placeholder}
                                                        className={`${field.hasError ? 'input-error' : ''}`}
                                                        value={field.value}
                                                        onChange={(event) => setValue(groupIndex, index, event.target.value)}
                                                    />

                                                    { field.errorText ?  <div className={'error-text'}>{ field.errorText }</div> : null }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }

                        <div className={'button-container'}>
                            <button type={'submit'} className={'button button-main'}  disabled={changedFields() === 0}>
                                {
                                    updatingContact ? <Spinner size={16} color={'#f2f2f2'} thickness={2}/>
                                        : <span>Save changes</span>
                                }
                            </button>
                        </div>
                    </form>
                </div>

                <SliderFeedback type={feedback.type} text={feedback.text} open={feedback.open}/>
                <SafeBar open={changedFields() > 0} onSave={() => updateInfo()} onDiscard={() => discardChanges()} saving={updatingContact}/>
            </>
        }
    </div>
}

interface safeType {
    open: boolean,
    onSave: () => void,
    onDiscard: () => void,
    saving: boolean
}
const SafeBar:React.FC<safeType> = ({ open, onSave, onDiscard, saving }) => {
    return <div className={`safe-bar ${open ? 'open' : 'closed'}`}>
        <div className={'page-width flexbox centered safe-content'}>
            <button className={'button button-transparent white-transparent'} onClick={() => onDiscard()}>Discard changes</button>
            <button className={'button button-main'} disabled={saving} onClick={() => onSave()}>{ saving ? 'Saving...' : 'Save changes '}</button>
        </div>
    </div>
}

export default ProfileView
