import React, {useEffect, useState} from 'react';
import "../css/components/position-placeholder.scss";

interface propTypes {
    number: number
}

const PositionPlaceholder:React.FC<propTypes> = ({ number}) => {
    const [itemArray, updateItemArray] = useState<Array<string>>([]);
    useEffect(() => {
        let items = new Array(number).fill('');
        updateItemArray(items)
    }, [number])

    return <div className={'placeholder-items'}>
        {
            itemArray.map((item, index) => {
                return <div className={'position-item'} key={index}>
                    { index % 3 === 0 ? <div className={'initial-placeholder'}><span></span></div> : null}
                        <div className={'flexbox align-center'}>
                            <div className={'image-placeholder'}/>
                            <div className={'user-placeholder'}/>
                        </div>
                </div>
            })
        }

        <div className={'initial-list'}>
            {
                itemArray.filter((item, index) => index % 3 === 0).map((initialItem, initialIndex) => {
                return <span key={initialIndex}/>
            })
            }
        </div>
    </div>
}

export default PositionPlaceholder