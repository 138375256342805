import React, {useEffect, useState} from "react";
import "../css/components/media-element.scss";
import {BsFillPlayCircleFill, BsFillPlayFill, BsMusicNoteBeamed} from "react-icons/bs";
import {AiFillFile, AiFillPicture} from "react-icons/ai";
import PictureElement from "./PictureElement";
import AudioPlayer from "./AudioPlayer";
import VideoPlayer from "./VideoPlayer";
import FileDownloader from "./FileDownloader";
import { mediaType } from "../app/redux/storeTypes";

interface propTypes {
    mediaType: mediaType,
    loaded: boolean,
    src: string
}
const MediaElement:React.FC<propTypes> = ({ mediaType, loaded, src}) => {
    const [placeholderElement, updatePlaceholderElement] = useState<JSX.Element>(<></>);



    useEffect(() => {
       // console.log({ mediaType })
        function getPlaceholder(mediaType : mediaType) :JSX.Element {
            switch (mediaType) {
                case "audio":
                    return <BsMusicNoteBeamed/>
                case "file":
                    return <AiFillFile/>
                case "video":
                    return <BsFillPlayCircleFill/>
                case "voice-note":
                    return <BsFillPlayFill/>
                case "picture":
                    return <AiFillPicture/>
                default:
                    return <AiFillPicture/>
            }
        }

        updatePlaceholderElement(getPlaceholder(mediaType))
    }, [updatePlaceholderElement, mediaType])
  return <div className={'media-element'}>
          {
              !loaded ? <>
                  <div className={`media-placeholder ${mediaType === 'voice-note' ? 'voice-note' : ''}`}>
                      { placeholderElement }
                  </div>
              </>: <>
              {
                  mediaType === 'picture' ? <PictureElement src={src}/> :
                      mediaType === 'audio' ? <AudioPlayer src={src} layout={'regular'}/> :
                          mediaType === 'video' ? <VideoPlayer src={src}/> :
                              mediaType === 'file' ? <FileDownloader src={src}/> :
                                  mediaType === 'voice-note' ? <AudioPlayer src={src} layout={'flex'}/> : null
              }
              </>
          }
      </div>
}

export default MediaElement
