import React, {useEffect, useState} from "react";
import "../css/components/progress-bar.scss";

interface propTypes {
    current: number,
    total: number,
    progressText?: string,
    onComplete?: () => void
}

const ProgressBar:React.FC<propTypes> = ({ current, total, progressText, onComplete}) => {

    const [barWidth, setBarWidth] = useState<number>(0);

    useEffect(() => {
        if (Math.round(barWidth) === 99) {
            if (onComplete) {
                onComplete();
            }
        }
    }, [barWidth])

    useEffect(() => {
        setBarWidth( current / total )
    }, [ current, total ])

    return <div className={'progress-bar'}>
        {progressText ? <div className={'progress-text'}>{ progressText }</div> : null}
        <div className={'progress-bg'}>
            <div className={'progress-line'} style={
                {
                    width: `${barWidth * 100}%`
                }
            }>

            </div>
        </div>
    </div>
}

export default ProgressBar
