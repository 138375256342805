import React from "react";
import ImagePlaceholder from "../ImagePlaceholder";
import "../../css/components/chats/space-image.scss"
import {baseURL} from "../../api/base";

interface propTypes {
    active: boolean,
    src?: string
}
const SpaceImage:React.FC<propTypes> = ({ active, src}) => {
   return <div className={'space-image'}>
       { src && src !== '' && src !== baseURL ? <img className={'user-image'} src={src}/> : <ImagePlaceholder/>}
        <span className={`item-status ${active ? 'active': 'inactive'}`}/>
    </div>
}

export default SpaceImage
