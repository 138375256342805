import React, {FormEvent, useEffect, useRef, useState} from "react";
import "../../css/components/chats/space.scss";
import "../../css/components/chats/space-image.scss";
import ImagePlaceholder from "../ImagePlaceholder";
//import {IoEllipsisVertical} from "react-icons/io5";
import {GrAttachment, GrRefresh} from "react-icons/gr";
import {RiSendPlane2Fill} from "react-icons/ri";
import appStore from "../../app/redux/store";
import {baseApi, baseURL} from "../../api/base";
import {useParams, useNavigate, Link} from "react-router-dom";
import ProgressBar from "../ProgressBar";
import { v4 as uuidv4 } from 'uuid';
import {
    contactType, feedbackType,
    messageDataType,
    spaceType,
    userDataType
} from "../../app/redux/storeTypes";
import {
    addMessage, deleteSpaceMessage,
    editSpaceMessageData,
    MarkMessagesDelivered,
    MarkMessagesRead, updateSpaceMessages,
    updateStateMessage
} from "../../app/redux/actions";

import {
    dataInterface, editSpaceMessage, getSpaceData,
    listenForNotifications,
    listenForTyping, messageDataInterface, sendDeleteRequest,
    sendTypingNotification,
    SetMessageStatus, updateMessageCount
} from "../../api/pusherFunctions";
import {months, prefixDate} from "../../helpers/dateFunctions";
import {debounce} from "lodash";
import EmojiPicker from "../EmojiPicker";
import {getFileNameAndExtension, getMediaType} from "../../helpers/mediaFunctions";
import MediaElement from "../MediaElement";
import VoiceRecorder from "../VoiceRecorder";
import {BsFillPencilFill, BsTrash} from "react-icons/bs";
import {HiDotsVertical} from "react-icons/hi";
import SliderFeedback from "../SliderFeedback";


interface chatSpaceType{
    updatedMember?: contactType,
    onMessageUpdate?: () => void
}

interface spaceGroupType {
    date: string,
    messages: messageDataType[]
}

interface spaceInterface {
    space: string,
    groupedMessages: spaceGroupType[]
}

const ChatSpace:React.FC<chatSpaceType> = ({ updatedMember, onMessageUpdate }) => {
    let parameters = useParams(), navigate = useNavigate();
    let feedbackObject:feedbackType = {
        type: "error",
        text: "",
        open: false
    }
    const [timeout] = useState<number>(30000);
    const [loadingSpace, setLoadingSpace] = useState<boolean>(true);
    const [requestTimer, updateRequestTimer] = useState<number>(0);
    const [loadingError, setLoadingError] = useState<boolean>(false);
    const [spaceData, updateSpaceData] = useState<spaceInterface | {}>({});
    const [member, updateMember] = useState<contactType | {}>({});
    const [chatError, setChatError] = useState(feedbackObject);

    let requestTimeout = useRef<null | number>(0);
    let chatBox = useRef<HTMLDivElement | null>(null);


    function getDate(dateString: string) {
        return new Date(dateString).toISOString().slice(0, 10)
    }

    function handleError(error: any) : void {
        //console.log({ error: error.message})
        setChatError({...chatError, type: "error", text: error.message, open: true})
    }

    function handleSuccess() {
        if (chatError.open) {
            setChatError({...chatError, type: "success", text: "", open: false})
        }
    }

    function createSpaceGroup(chatSpace: spaceType) {
        if ("messages" in chatSpace && chatSpace) {
            let messageDates:Array<string> = [];


                chatSpace.messages.forEach(message => {
                    let date = getDate(message.created_at);
                    if (messageDates.indexOf(date) === -1) {
                        messageDates.push(date)
                    }
                })

            let messageGroup:spaceInterface = {
                space: chatSpace.space,
                groupedMessages: messageDates.map(date => {
                    let dateData = new Date(date)
                    return {
                        date: `${months[dateData.getMonth()]} ${dateData.getDate()}`,
                        messages: chatSpace.messages.filter(message => getDate(message.created_at) === date)
                    }
                })
            }

            return messageGroup
        }
    }

    function MarkAllMessagesAsRead() {
        let user = appStore.getState().user as userDataType

        if (parameters.id) {
            SetMessageStatus(user.id, parameters.id, user.token, 'read')
                .then(() => handleSuccess()).catch(error => handleError(error))

               /* .then(response => {
                   console.log('Messages marked:', response);
                }).catch(error => {
                console.log({ error })
            })*/

        }
    }



    function updateMessageState(data: dataInterface, status: 'delivered' | 'read') {

        let user = appStore.getState().user as userDataType;
        if (user.id !== data.user) {
            let space = appStore.getState().spaces.find(space => space.space === parameters.id);

            if (space) {
                let action = status === 'read' ? MarkMessagesRead(space.id, user.id) : MarkMessagesDelivered(space.id, user.id);
                appStore.dispatch(action);

                let spaceItem = appStore.getState().spaces.find(space => space.space === parameters.id);
                if (spaceItem) {
                    let spaceGroup = createSpaceGroup(spaceItem);

                //    console.log({ data, status });

                    updateSpaceData({...spaceGroup})

                    if (status === 'read') {
                      //  console.log('Messages marked as read')
                        if (onMessageUpdate) {
                            onMessageUpdate()
                        }
                    }



                   /* if (status === 'read') {
                        let existingSpaces = appStore.getState().spaces.filter(space => space.member === data.user || space.contacts_id === data.user);

                        if (existingSpaces.length > 0) {
                            updateMessageCount()
                        }
                    }*/
                }

            }
        }
    }

   useEffect(() => {

       let messageChannel = listenForNotifications();

       let eventType:string = "new.message";

       const eventCallback = (messageEvent: messageDataInterface) : void => {
        console.log({ messageEvent });

           let store = appStore.getState();
           if ("spaces" in store && store) {

               let existingSpaces = store.spaces.filter(space => space.space === messageEvent.space_id);

               if (existingSpaces.length > 0) {
                 //  console.log('New message sent!')
                   updateMessageCount()
               }

               let matchingSpace = store.spaces.find(space => space.space === messageEvent.space_id);

               if (matchingSpace) {
                   let user = store.user as userDataType

                   if (messageEvent.message.sender !== user.id) {
                       let eventMessage = {...messageEvent.message, saved: true}
                       if (messageEvent.message.message_type === 'file') {
                           eventMessage = {...eventMessage, ...getFileNameAndExtension(messageEvent.message.message as string)}
                       }
                       let existingMessage = matchingSpace.messages.find(message => message.message_id === messageEvent.message.message_id && message.saved);

                       if (!existingMessage) {
                           appStore.dispatch(addMessage(matchingSpace.id, eventMessage))
                           let existingSpace = appStore.getState().spaces.find(space => space.space === messageEvent.space_id);

                           if (existingSpace) {
                               let spaceGroup = createSpaceGroup(existingSpace);

                               updateSpaceData({...spaceGroup});
                               MarkAllMessagesAsRead()

                               if (onMessageUpdate) {
                                   onMessageUpdate()
                               }
                           }
                       }


                   }
               }
           }
       }

       let messageReadEvent:string = "messages.read";
       const messageEventCallback = (data: dataInterface) : void => {
       //  console.log({ 'Read data': data})
           updateMessageState(data, 'read')
       }


       let messageDeliveredEvent:string = "message.delivered";

       const deliveryCallback = (deliveryData: dataInterface) : void => {
          //console.log({ deliveryData })
           updateMessageState(deliveryData, 'delivered')
       }

       const editedMessageEvent:string = "edited.message";

       interface editedDataType {
           space: string,
           message: messageDataType
       }

       const editedMessageCallback = (data: editedDataType) : void => {
           let existingSpace = appStore.getState().spaces.find(spaceItem => spaceItem.space === data.space);

           if (existingSpace){

               let savedMessage = {...data.message, saved: true}
               appStore.dispatch(editSpaceMessageData(existingSpace.id, savedMessage))

               updateAndCheckForLastMessage(existingSpace, savedMessage)
           }
       }

       function updateAndCheckForLastMessage(existingSpace: spaceType, message: messageDataType) : void {

           updateMessages();

           let spaceMessages = existingSpace.messages;
           if ((spaceMessages[spaceMessages.length-1].id === message.id) && onMessageUpdate) {
               onMessageUpdate()
           }
       }

       const deleteEvent:string = "message.deleted";

       interface deleteType {
           space_id: string,
           message_id: string
       }

       const deleteCallback = (data: deleteType) : void => {
        //   console.log({ data })

           let existingSpace = appStore.getState().spaces.find(space => space.id === parseInt(data.space_id));

           if (existingSpace) {
               let existingMessage = existingSpace.messages.find(message => message.id === parseInt(data.message_id));

               refreshMessages()

               if (existingMessage){
                   appStore.dispatch(deleteSpaceMessage(existingSpace.id, existingMessage.id));

                   updateAndCheckForLastMessage(existingSpace, existingMessage)

               }
           }
       }

       messageChannel.bind(deleteEvent, deleteCallback);
       messageChannel.bind(editedMessageEvent, editedMessageCallback)
       messageChannel.bind(messageDeliveredEvent, deliveryCallback)
       messageChannel.bind(messageReadEvent, messageEventCallback)
       messageChannel.bind(eventType, eventCallback)


       let space = appStore.getState().spaces.find(space => space.space === parameters.id);


       if (space) {
         //  console.log({ space })
           let user = appStore.getState().user as userDataType;
           if ("id" in user && user) {
               let member = space.members.find((member: contactType) => member.id !== user.id);

               if (member) {
                   updateMember({...member})
                   updateSpaceData({... createSpaceGroup(space)})
               }

           }

       }


   }, [parameters])

  /*  const handleLoadError = () => {
        setLoadingError(true);
        setLoadingSpace(true)

        if (requestTimeout.current !== null) {
            window.clearTimeout(requestTimeout.current)
        }
    }*/

    useEffect(() => {
        updateMember({...updatedMember})

        let user = appStore.getState().user as userDataType;
        let spaces = appStore.getState().spaces as spaceType[];
        if (updatedMember && (updatedMember.id !== user.id) && updatedMember.active) {
            let existingSpace = spaces.find(space => space.space === parameters.id) ;

            if (existingSpace) {
                SetMessageStatus(updatedMember.id, existingSpace.space, user.token, 'delivered')
                /*    .catch(error => {
                        console.log({ errorMessageStatus: error.message })
                    });*/
                 /*   .then(response => {
                        console.log({ deliveredMessages: response })
                    }).catch(error => {
                        console.log({ error })
                })*/
            }

        }
    }, [updatedMember])

    useEffect(() => {
           if (requestTimer !== timeout) {
               requestTimeout.current = window.setTimeout(() => {
                   updateRequestTimer(requestTimer + 500)
               }, 500)
           }
           //eslint-disable-next-line
    }, [requestTimer, timeout]);

   /* useEffect(() => {
        console.log('Loading space:', loadingSpace)
    }, [loadingSpace])*/

   function createSpaceData(responseData: spaceType, dispatchToStore = false) {
       responseData.messages = responseData.messages.map(message => {
           message.saved = true;

           if (message.message_type === 'file') {
               let fileData = getFileNameAndExtension(message.message as string);

               message.file_extension = fileData.file_extension;
               message.file_name = fileData.file_name;
           }
           return message
       })
       if (dispatchToStore) {
           appStore.dispatch(updateSpaceMessages(responseData.id, responseData.messages))
       }

       let spaceGroup:spaceInterface = createSpaceGroup(responseData) as spaceInterface
       updateSpaceData({...spaceGroup})

    }

    function fetchMessages() {
        setLoadingSpace(true);
        setLoadingError(false);
        updateRequestTimer(0);
        updateMember({});

        let store = appStore.getState();



        if ("spaces" in store && store) {
            let existingSpace = store.spaces.find(space => space.space === parameters.id);

            let storeUser = store.user as userDataType;

            if (!existingSpace) {
               getSpaceData(parameters.id as string, storeUser.id, storeUser.token).then(response => {
                      // console.log({ messageData: response.data })
                        setLoadingSpace(false);

                        let responseData:spaceType = response.data;

                        createSpaceData(responseData)

                        let member = responseData.members.find((member: contactType) => member.id !== storeUser.id);
                        updateMember({...member});

                        updateRequestTimer(timeout);


                    }).catch(() => {
                        setLoadingSpace(false);
                        setLoadingError(true);
                })
            } else {
              // console.log({ existingSpace })

                updateMessageCount()

                setLoadingSpace(false);

                updateRequestTimer(timeout);
                let spaceItems = createSpaceGroup(existingSpace) as spaceInterface;
                updateSpaceData({...spaceItems});

                let member = existingSpace.members.find((member: contactType) => member.id !== storeUser.id);
                updateMember({...member});
            }


        }
    }

    useEffect(() => {
        scrollToBottom()
    }, [chatBox, spaceData])

    function scrollToBottom() : void {
        if (chatBox.current != null) {
            let spaceContainer = chatBox.current as HTMLDivElement;

            spaceContainer.scroll({
                top: spaceContainer.scrollHeight,
                left: 0,
                behavior: "smooth"
            })

          //  console.log({ scrollTop: spaceContainer.scrollTop })
        }

    }



    useEffect(() => {
        if ("id" in appStore.getState().user && appStore.getState()) {
            fetchMessages();

          //  console.log('Messages to be marked as read from re-render')
            MarkAllMessagesAsRead();

        } else {
            navigate('/login')
        }

    }, [])

  /*  let notifChannel = listenForStatusChange();

    let eventType:string = "user.status";

    let eventCallback = (eventData: eventInterface) : void => {
        let storeUser = appStore.getState().user;

      //  console.log({ spaceEventData: eventData, memberId: member})

        if ("id" in storeUser && storeUser) {
            if ("id" in member && member) {
                if (eventData.user === member.id) {
                    let spaceData = getSpacePosition(eventData);

                   if (spaceData) {
                       if (spaceData.spaceIndex !== -1 && spaceData.memberIndex !== -1) {
                           appStore.dispatch(updateSpaceMember(spaceData.spaceIndex, spaceData.memberIndex, spaceData.member))


                       }
                   }

                    updateMember({...member, active: eventData.active})
                }
            }
        }
    }

    notifChannel.bind(eventType, eventCallback);*/

    const updateMessages = () : void => {
        let store = appStore.getState()
        if ("spaces" in store && store) {
           // console.log({ spaces: store.spaces })
            let matchingSpace = store.spaces.find(space => space.space === parameters.id);

            if (matchingSpace) {

                let spaceGroup = createSpaceGroup(matchingSpace) as spaceInterface

                updateSpaceData({...spaceGroup})
                scrollToBottom()

                /*let spaceContainer = chatBox.current as HTMLDivElement;

                spaceContainer.scroll({ top: spaceContainer.scrollHeight + 150, behavior: "smooth"})*/
                if (onMessageUpdate) {
                    onMessageUpdate()
                }
            }
        }
    }

    const refreshMessages = () => {
        let currentSpace = appStore.getState().spaces.find(space => space.space === parameters.id)

        if (currentSpace) {
            let user = appStore.getState().user as userDataType;
            getSpaceData(currentSpace.space, user.id, user.token).then(response => {
                let responseData:spaceType = response.data;

              //  console.log({ responseData })
              //  createSpaceData(responseData, true)
            })
        }
    }


    return <div className={'main-space'} ref={element => chatBox.current = element}>
        <SliderFeedback type={chatError.type} text={chatError.text} open={chatError.open} />
        {
            loadingSpace ? <><ProgressBar current={requestTimer} total={timeout} /></> :
               <>
                   {
                       !loadingError ? <>
                           <SpaceHeader member={member as contactType} />

                           <div className={'chat-space'}>
                               <div className={'chat-box'}>
                                  {/* <div className={'chat-message receiver'}>Hey bro, how are you?</div>
                                   <div className={'chat-message sender'}>I'm alright bro , you?</div>

                                   <div className={'chat-message receiver'}>I'm okay my bro, What you up to?</div>
                                   <div className={'chat-message sender'}>Working on some tasks, you?</div>*/}

                                   {

                                       "groupedMessages" in spaceData && spaceData.groupedMessages.length === 0 ? <div className={'conversation-starter'}>
                                           There are no messages in this space yet. Start a conversation now.
                                       </div> : <>
                                       {
                                         "groupedMessages" in spaceData ? spaceData.groupedMessages.map((data, dataIndex) => {
                                             return <div className={'chat-group'} key={dataIndex}>
                                                 <div className={'chat-date'}>{ data.date }</div>
                                                 <>
                                                     {
                                                         data.messages.map((message, messageIndex) => {
                                                             return <div className={`chat-message ${!message.saved ? 'faded': ''} ${"id" in member ? message.sender === member.id ? 'receiver' : 'sender' : ''}`} key={messageIndex}>
                                                               <div className={`data-item flexbox`}>
                                                                   {
                                                                      message.sender !== (member as contactType).id ? <MessageActions
                                                                                                                        message={message}
                                                                                                                        space={parameters.id as string}
                                                                                                                        onAction={() => updateMessages()}
                                                                                                                        onSuccess={() => handleSuccess()}
                                                                                                                        onError={(error) => handleError(error)}
                                                                                                                        /> : null

                                                                   }
                                                                   <div className={`message-item ${message.message_type === 'file' ? 'file-item' : ''}`}>
                                                                       {
                                                                           message.message_type === 'text' ?  <span>{ message.message as string }</span> : <>
                                                                               {
                                                                                   message.message_type === 'file' ?

                                                                                       <MediaElement
                                                                                           mediaType={ (message.file_extension as string !== 'mpeg') ? getMediaType(message.file_extension as string) : "voice-note" }
                                                                                           loaded={ message.saved }
                                                                                           src={ `${baseURL}${message.message as string }` }
                                                                                       /> : null
                                                                               }
                                                                           </>
                                                                       }
                                                                   </div>
                                                               </div>
                                                                 <span className={'message-status'}>
                                                                     {
                                                                       message.sender === (appStore.getState().user as userDataType).id ?  <span className={'message-state'}>
                                                                         {
                                                                             message.status.read_by_receiver ? "Read"
                                                                                 : message.status.delivered_to_receiver ? "Delivered"
                                                                                     :message.status.sent_to_receiver ?
                                                                                         "Sent" : ""
                                                                         }
                                                                     </span> : null
                                                                     }
                                                                     <span className={'message-time'}>
                                                                         { prefixDate(new Date(message.created_at).getHours()) }
                                                                         :
                                                                         { prefixDate(new Date(message.created_at).getMinutes()) }
                                                                     </span>
                                                                 </span>
                                                             </div>
                                                         })
                                                     }
                                                 </>
                                             </div>
                                         }) : null
                                       }
                                       </>
                                   }
                               </div>
                               <ChatActions
                                   spaceId={parameters.id as string}
                                   member={member as contactType}
                                   onMessageSend={() => updateMessages()}
                                   onError={error => handleError(error)}
                                   onSuccess={() => handleSuccess()}
                               />
                           </div>
                       </> : <div className={'space-error'}>
                           <div className={'space-warning'}>Error getting space. <span className={'reset'} onClick={() => fetchMessages()}><GrRefresh/>Retry</span></div>
                       </div>
                   }
               </>
        }
    </div>
}

interface actionTypes {
    spaceId: string,
    member: contactType,
    onMessageSend: () => void,
    onError: (error: any) => void,
    onSuccess: () => void
}

interface feedbackObject {
    display: boolean,
    text: string
}
const ChatActions:React.FC<actionTypes>  = ({ spaceId, member, onMessageSend, onError, onSuccess}) => {
    const [message, updateMessage] = useState<string>('');
    const [space, updateSpace] = useState<spaceType | {}>({});
   const [typingFeedback, updateTypingFeedback] = useState<string>('');
   const [uploadFeedback, setUploadFeedback] = useState<feedbackObject | {}>({});

    let clearDelay = useRef<number>(0);

    useEffect(() => {
      if (message.length >= 2) {
          let space = appStore.getState().spaces.find(space => space.space === spaceId);
          let user = appStore.getState().user as userDataType;
          let typingController = new AbortController();
          let typingSignal = typingController.signal;
          let clearController = new AbortController();
          let clearSignal = clearController.signal;


          const sendTypingRequest = debounce((space: spaceType) => {

              sendTypingNotification(space.id, user.id, user.token, "broadcast", typingSignal)
                  .then(() => onSuccess())
                  .catch(error => {
                     // console.log({ error })
                      if (error.code !== 'ERR_CANCELED') {
                          onError(error)
                      }

                  })
          }, 1000)

          if (space) {
              window.clearTimeout(clearDelay.current);
              sendTypingRequest(space)
              clearDelay.current = window.setTimeout(() => {
                //  console.log('Timeout reached')
                  if (space) {
                     let clearTypingRequest = debounce((space: spaceType) => {
                         sendTypingNotification(space.id, user.id, user.token,"clear", clearSignal)
                             .then(() => onSuccess())
                             .catch(error => {
                                 if (error.code !== 'ERR_CANCELED') {
                                     onError(error)
                                 }
                             });
                     }, 1000)

                      clearTypingRequest(space)
                  }

              }, 3000)

          }

          return () => {
              typingController.abort();
              clearController.abort();
          }
      }


    }, [message])

    useEffect(() => {
        if ("display" in uploadFeedback && uploadFeedback) {
            if (uploadFeedback.display) {
                setTimeout(() => {
                   setUploadFeedback({...uploadFeedback, display: false, text: ""})
                }, 3000)
            }
        }
    }, [uploadFeedback])



    useEffect(() => {
        let matchingSpace = appStore.getState().spaces.find(space => space.space === spaceId);
        let user = appStore.getState().user as userDataType;

        updateSpace({...matchingSpace});


        let notificationChannel = listenForTyping();

        let typingEvent:string = "user.typing", typingClearEvent:string = "user.typing.clear";

        interface typingInterface {
            user: number,
            space: number
        }


        const typingCallback = (typingData: typingInterface) : void => {
            if (typingData.user !== user.id) {
                let message = `${member.firstname} is typing...`
                updateTypingFeedback(message)
            }
        }

        const typingClearedCallback = (clearedData: typingInterface) : void => {
            if (clearedData.user !== user.id) {
             //   console.log({ clearedData })
               updateTypingFeedback('')
            }

        }

        notificationChannel.bind(typingEvent, typingCallback);
        notificationChannel.bind(typingClearEvent, typingClearedCallback)
    }, [spaceId])


    const sendMessage = (messageText: string | File, messageType: "text" | "file", messageId:number) : void => {



        let storeUser = appStore.getState().user, spaceItem = space as spaceType;

        if ("id" in storeUser && storeUser) {
            interface formObjectType {
                space: string,
                message: string | File,
                sender: number,
                receiver: number,
                message_type: string,
                message_id: string
            }

            let message_id = uuidv4();

            let formObject: formObjectType = {
                space: spaceItem.space,
                message: messageType === 'text' ? messageText as string : messageText as File,
                sender: storeUser.id,
                receiver: member.id,
                message_type: messageType,
                message_id
            }

            let formData = new FormData();

          //  console.log({ formObject })

            for (let key in formObject) {
                let value = formObject[key as keyof formObjectType];

                const formValue = `${ value }`;
                if (key !== 'message') {
                    formData.append(key,  formValue)
                } else {
                    if (key === 'message' && messageType === 'text') {
                        formData.append(key,  formValue)
                    } else {
                        formData.append(key, value as File)
                    }
                }

            }


            let dateTime = new Date().toISOString();
            let reduxMessage:messageDataType = {
                id: messageId,
                space_id: spaceItem.id,
                message: messageType === 'text' ? messageText as string : URL.createObjectURL(messageText as File),
                message_type: messageType,
                message_id,
                sender: storeUser.id,
                receiver: member.id,
                saved: false,
                created_at: dateTime,
                updated_at: dateTime,
                status: {
                    sent_to_receiver: false,
                    delivered_to_receiver: false,
                    read_by_receiver: false
                }
            }

            if (messageType === 'file') {
                reduxMessage = {...reduxMessage, ...getFileNameAndExtension((messageText as File).name)}
            }


            //console.log({ spaceId: spaceItem.id, reduxMessage })
            appStore.dispatch(addMessage(spaceItem.id, reduxMessage))
            onMessageSend()
            baseApi.post('add/message', formData, {
                headers: {
                    Accepts: 'application/json',
                    Authorization: `Bearer ${storeUser.token}`
                }
            }).then(response => {


                let responseData:messageDataType ={...response.data.message, saved: true};

              // console.log(responseData)

                if (responseData.message_type === 'file') {
                    let fileData = getFileNameAndExtension(responseData.message as string);

                    responseData = {...responseData, ...fileData}
                }


        //  console.log({ 'Store before dispatch': appStore.getState().spaces })

               appStore.dispatch(updateStateMessage(spaceItem.id, responseData))
            //   console.log('Spaces:', appStore.getState().spaces)

                onMessageSend()

            })/*.catch(error => {
                console.log({ error })
            })*/
        }
    }


    const handleSubmit = (event: FormEvent) : void => {
        event.preventDefault();
        let spaceData = appStore.getState().spaces as spaceType[] ;

        let matchingSpace = spaceData.find(space => space.space === spaceId);
        if (matchingSpace && message !== '') {
            let messages = (matchingSpace as spaceType).messages;
            let messageId = messages.length > 0 ? messages[messages.length-1].id + 1 : messages.length+1;
            //console.log({ lastMessageId: messages[messages.length-1].id,  messageId })
            sendMessage(message, "text", messageId)
            updateMessage("");
        }
    }

    const handleUpload = (event: FormEvent) : void => {
        let target = event.target as HTMLInputElement;
        const maxUploadSize = 30;

        if (target.files) {
            let file:File = target.files[0];

            let fileSize = file.size / 1000000;


            if (fileSize > maxUploadSize) {
                let feedback:feedbackObject = {
                    display: true,
                    text: `Max file upload size is ${maxUploadSize}MB`
                }

                setUploadFeedback({...feedback})
            } else {
                uploadFile(file as File)
            }
        }

    }

    function uploadFile(file: File) : void {
        let spaces = appStore.getState().spaces as spaceType[] ;
        let existingSpace = spaces.find(space => space.space === spaceId);

        if (existingSpace) {
            sendMessage(file, "file", (existingSpace as spaceType).messages.length+1)
        }
    }


    return <form className={'chat-action-section'} onSubmit={event => handleSubmit(event)}>
        <div className={'typing-notification'}>{ typingFeedback }</div>
       <div className={'actionable-items'}>
           <div className={'upload-section'}>
               <input type={"file"}  id={'file-upload'} onChange={event => handleUpload(event)}/>
               <label htmlFor={'file-upload'}>
                   <GrAttachment className={'attachment-icon'}/>
               </label>
           </div>

           <div className={'input-control'}>
              <EmojiPicker onEmojiSelect={(emojiObject) => updateMessage(`${message} ${emojiObject.emoji}`)}/>
               <input
                   type={'text'}
                   placeholder={'Type a message'}
                   value={message}
                   onChange={event => updateMessage(event.target.value)}
               />

               <div className={'recorder-section'}>
                   <VoiceRecorder onRecordEnd={audio => uploadFile(audio)}/>
               </div>
           </div>

           <button className={'air-button'}>
               <RiSendPlane2Fill className={'air-icon'}/>
           </button>
       </div>

        <div className={`feedback-container error ${"display" in uploadFeedback && uploadFeedback.display ? 'open' : 'closed'}`}>
            { "text" in uploadFeedback ? uploadFeedback.text : "" }
        </div>
    </form>
}





interface optionInterface  {
    name: string,
    errorType?: boolean,
    icon?: JSX.Element
}

interface spacePropTypes {
    member: contactType
}

const SpaceHeader:React.FC<spacePropTypes> = ({ member }) => {
    let userOptions:optionInterface[] = [
        {
            name: 'Delete chat',
            errorType: true
        }
    ]

    const [chatOptions] = useState<optionInterface[]>(userOptions);
    const [showOptions, setShowOptions] = useState<boolean>(false);

    const handleSelect = (option: string) => {
            //console.log('Option selected:', option);
            setShowOptions(false);

    }

    /*useEffect(() => {
        console.log('Dropdown state:', showOptions)
    }, [showOptions])*/

    return  <div className={'space-header'}>
        <div className={'header-data'}>
            { member.profile_picture !== '' ? <img src={`${baseURL}${member.profile_picture}`}/> :  <ImagePlaceholder/>}
            <div className={'main-data'}>
                <div className={'data-row'}>
                    <Link to={`/contacts/${member.id}`} className={'username'}>{member.firstname} {member.lastname[0]}.</Link>
                    <span className={`item-status ${member.active ? 'active' : 'inactive'}`}/>
                </div>
                <div className={`user-status ${member.active ? 'active' : 'inactive'}`}>{ member.active ? 'Online' : 'Offline' }</div>
            </div>
        </div>

        <div className={'chat-option-section'}>
          {/*  <IoEllipsisVertical
                className={'vertical-icons toggle-chat-options'}
                onClick={() => setShowOptions(!showOptions)}
            />

            { showOptions ? <ChatOptionDropdown
                options={chatOptions}
                onSelect={option => handleSelect(option)}
                onOutsideClick={() => setShowOptions(false)}
            /> : null}*/}
        </div>




    </div>
}

interface propTypes {
    options: optionInterface[],
    onSelect: (option: string) => void,
    onOutsideClick: () => void
}
const ChatOptionDropdown:React.FC<propTypes> = ({ options, onSelect, onOutsideClick }) => {
    useEffect(() => {
        document.addEventListener("click", function (event) {
            let target = event.target as HTMLElement;
            if (target.closest(".option-dropdown") == null && target.closest(".toggle-chat-options") == null) {
                onOutsideClick()
            }
        })

        // eslint-disable-next-line
    }, [])
    return <div className={'option-dropdown'}>
            <ul className={'option-list'}>
                {
                    options.map((option, index) => {
                        return <li key={index} onClick={() => onSelect(option.name)} className={`${option.errorType ? 'error-type' : ''}`}>
                            { option.name }
                        </li>
                    })
                }
            </ul>
        </div>
}

interface messageActionTypes {
    message: messageDataType,
    space: string,
    onAction: () => void,
    onSuccess: () => void,
    onError: (error: any) => void
}

interface actionOptionTypes {
    icon: JSX.Element,
    text: string,
    type: string,
    action: () => void
}

const MessageActions:React.FC<messageActionTypes> = ({ message, space, onAction, onSuccess, onError }) => {

    const [editMode, setEditMode] = useState<boolean>(false);
    let messageOptions:actionOptionTypes[] = [
        {
            icon: <BsFillPencilFill/>,
            text: "Edit",
            type: "edit",
            action: () => setEditMode(true)
        },

        {
            icon: <BsTrash/>,
            text: "Delete",
            type: "delete",
            action: () => deleteMessage()
        }
    ]

    const [options, setOptions] = useState<actionOptionTypes[]>(messageOptions);
    const [openActions, setOpenActions] = useState<boolean>(false);
    const [editValue, setEditValue] = useState<string>(message.message as string);


    useEffect(() => {
       if (message.message_type === 'file') {
           setOptions(options.filter(option => option.type !== 'edit'))
       }
    }, [message])

    useEffect(() => {
        document.addEventListener("click", function (event) {
            let target = event.target as HTMLElement;
            if (target.closest(".action-element") == null) {
                setOpenActions(false)
            }
        })
    }, [])

    useEffect(() => {
        if (!openActions) {
            setEditMode(false)
        }
    }, [openActions])

    function handleToggle() : void {
       document.body.click();

        setOpenActions(!openActions)
    }

    function deleteMessage() : void {
      // console.log({ message })

        let spaceItem = appStore.getState().spaces.find(spaceData => spaceData.space === space);

        if (spaceItem) {
          //  console.log(message.id)
            appStore.dispatch(deleteSpaceMessage(spaceItem.id, message.id))

            setOpenActions(false)
            onAction()
            let user = appStore.getState().user as userDataType;

            sendDeleteRequest(spaceItem.id, message.id, user.token)
              /*  .then(response => {
                console.log({ response })
            }).catch(error => {
                console.log({ error })
            })*/
        }

    }

    function editMessage() : void {
      //  console.log({ message })
        let spaceItem = appStore.getState().spaces.find(spaceData => spaceData.space === space);

        if (spaceItem) {
            let user = appStore.getState().user as userDataType;

            let newMessage = {...message, ...{message: editValue, saved: false}}

            appStore.dispatch(editSpaceMessageData(spaceItem.id, newMessage))
            onAction();

            setOpenActions(false)

            editSpaceMessage(spaceItem.id, message.id, editValue, user.token)
                .then(() => onSuccess()).catch(error => onError(error))
              /*  .then(response => {
                console.log({ response })
            }).catch(error => {
                console.log({ error })
            })*/
        }
    }

    return <div className={'action-section-data'}>
        <div className={'action-element'}>

            <HiDotsVertical className={'vertical-dots'} onClick={() => handleToggle()}/>

            <div className={`message-actions ${openActions ? 'open' : 'closed'}`}>

                      <ul style={{ display: `${!editMode ? 'block': 'none'}`}}>
                        {
                            options.map((option, index) => {
                                return <li key={index} className={`${option.type === 'delete' ? 'error' : ''}`} onClick={option.action}>
                                    { option.icon } <span>{ option.text }</span>
                                </li>
                            })
                        }
                    </ul>

                <div className={'edit-input'} style={{ display: `${editMode ? 'block': 'none'}`}}>
                        <div className={'input-control'}>
                            <input
                                type={'text'}
                                name={'edit-field'}
                                placeholder={'Edit message'}
                                value={editValue}
                                onChange={event => setEditValue(event.target.value)}
                            />
                        </div>

                        <div className={'flexbox button-row'}>
                            <button
                                className={'button button-regular'}
                                onClick={() => editMessage()}
                            >Save</button>
                            <button
                                className={'button button-transparent light-transparent'}
                                onClick={() => setEditMode(false)}
                            >Cancel</button>
                        </div>
                    </div>

            </div>


        </div>
    </div>
}

export default ChatSpace
