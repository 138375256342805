import React, {useEffect, useState} from "react";
import  '../css/components/switch.scss';
import { v4 as uuidv4 } from 'uuid';

interface propTypes {
    checked: boolean,
    onSwitch: (state : boolean) => void
}
const ModeSwitch:React.FC<propTypes> = ({ checked, onSwitch}) => {
    const [state, updateState] = useState(checked ? checked : false);
    const [componentId] = useState(uuidv4());

    useEffect(() => {

        updateState(checked)
    }, [checked])
    return (
        <>
            <input
                className={'checkbox'}
                type={'checkbox'}
                id={`switch-checkbox-${ componentId }`}
                checked={state}
                onChange={event => {
                    updateState(event.target.checked);
                    onSwitch(event.target.checked)
                }}
            />
            <label className={'slider'} htmlFor={`switch-checkbox-${ componentId }`}>
                <span className={'switch '}/>
            </label>
        </>
    )
}

export default ModeSwitch;
