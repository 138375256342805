import React, {useEffect, useRef, useState} from "react";
import Navigation from "../components/Navigation";
import { Outlet } from "react-router-dom";
import appStore from "../app/redux/store";
import {updateUserStatus} from "../app/redux/actions";
import {setUserStatus} from "../api/pusherFunctions";
import {authFieldType} from "../app/redux/storeTypes";

const BaseView:React.FC = () => {
    const [darkMode, setDarkMode] = useState<boolean>(true);
    let savedUser = appStore.getState().user;

    useEffect(() => {
        //localStorage.removeItem('page-mode')
      //localStorage.removeItem('userData')
         let savedMode = localStorage.getItem('page-mode');
         if (savedMode != null){
               let isInDarkMode = savedMode === 'dark';
               setDarkMode(isInDarkMode)
         }

    },[])

    useEffect(() => {
        let pageMode = darkMode ? 'dark' : 'light';
        document.body.setAttribute('data-mode', pageMode)
        localStorage.setItem('page-mode', pageMode)

    }, [darkMode])
    return (
        <>
            { savedUser ? <ActivityTracker/> : null}
            <Navigation darkMode={darkMode} switchMode={mode => setDarkMode(mode)}/>
            <div className={'page-container'}>
                <Outlet />
            </div>

        </>

    )
}

interface feedbackType {
    error: boolean,
    text: string
}
const ActivityTracker:React.FC = () => {
    let currentState:feedbackType = {
        error: false,
        text: "You are online!"
    }
    const [inActivityTimer, setInActivityTimer] = useState(0);
    const [timeout] = useState(60000);
    const [feedback, updateFeedback] = useState<feedbackType>(currentState)

    let inactivityTimeout = useRef<null | number>(null);

    useEffect(() => {
       inactivityTimeout.current = window.setTimeout(() => {
            setInActivityTimer(inActivityTimer + 1)
        }, timeout);

       if (inActivityTimer >= 15) {
           appStore.dispatch(updateUserStatus(false))
           window.clearTimeout(inactivityTimeout.current);
           setUserStatus();
       } else {
           let savedState = appStore.getState();
           if ("active" in savedState.user && savedState) {
               if (!savedState.user.active) {
                   appStore.dispatch(updateUserStatus(true));
                   setUserStatus()
               }
           }
       }

       // console.log({ inActivityTimer })

    }, [inActivityTimer]);

    useEffect(() => {
        let feedbackData:feedbackType | {} = {}

        window.addEventListener("online", function () {
            feedbackData = {
                error: false,
                text: "You are online"
            }

            updateFeedback({...feedbackData as feedbackType})
        })

        window.addEventListener("offline", function () {
            feedbackData = {
                error: true,
                text: "You are offline, please connect to the internet"
            }

            updateFeedback({...feedbackData as feedbackType})
        })






    }, [])

    useEffect(() => {
        function resetInActivityTimer():void {
            if (inactivityTimeout.current != null) {
                window.clearTimeout(inactivityTimeout.current);

                inactivityTimeout.current = window.setTimeout(() => {
                    setInActivityTimer(inActivityTimer + 1)
                }, timeout)
            }
            setInActivityTimer(0)
        }
        document.addEventListener('mousedown', resetInActivityTimer);
        document.addEventListener('mousemove', resetInActivityTimer);
        document.addEventListener('keydown', resetInActivityTimer);
        document.addEventListener('scroll', resetInActivityTimer);
        document.addEventListener('touchstart', resetInActivityTimer);

        //eslint-disable-next-line
    }, [])
    return <div className={`feedback-container ${feedback.error ? 'open' : 'closed'} ${feedback.error ? 'error' : 'success'}`}>
        { feedback.text }
    </div>
}

export default BaseView;
